import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isResizableImage } from 'lib/imageUtils';
import { Picture } from 'components/Picture';
import { layoutContext as layoutContextPropType } from 'lib/CustomPropTypes';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import './styles.themed.scss';

const block = 'background';

class Background extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    useBackground: PropTypes.bool.isRequired,
    imageUrl: PropTypes.string,
    styleOverride: PropTypes.shape({
      bgWrapper: PropTypes.string,
      bgPicture: PropTypes.string,
      bgBackground: PropTypes.string,
      bgGradient: PropTypes.oneOfType([
        PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.bool,
      ]),
      bgContent: PropTypes.oneOfType([
        PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.bool,
      ]),
      bgColor: PropTypes.string,
    }),
    animate: PropTypes.bool,
  };

  static contextTypes = {
    ...layoutContextPropType,
  };

  static defaultProps = {
    children: null,
    imageUrl: null,
    styleOverride: {
      bgWrapper: '',
      bgPicture: '',
      bgBackground: '',
      bgGradient: '',
      bgColor: '',
    },
    animate: false,
  };

  getGradient = (bgStyleOverride, i = 0) => (
    <div
      key={`${bgStyleOverride}${i}`}
      className={classNames(
        {
          'bg-background__gradient': !bgStyleOverride,
          [bgStyleOverride]: bgStyleOverride,
          hasImage: true,
        },
        'absolute absolute-fill',
      )}
      data-testid="bg-getGradient"
    />
  );

  getBackground = () => {
    const { imageUrl } = this.props;
    // There is no image provided
    if (!imageUrl) {
      // Display background color
      return this.color();
    }
    const { useBackground, styleOverride } = this.props;

    let bgGradient = null;
    // explicitly pass `false` to disable background gradient entirely
    // TODO: [BENTO-9040] refactor this to avoid coercing type
    if (styleOverride.bgGradient !== false) {
      bgGradient = Array.isArray(styleOverride.bgGradient)
        ? styleOverride.bgGradient.map(this.getGradient)
        : this.getGradient(styleOverride.bgGradient);
    }

    return (
      <>
        <div
          className={classNames(
            { 'bg-background': useBackground },
            styleOverride.bgBackground,
          )}
        >
          {this[useBackground ? 'background' : 'picture']()}
        </div>
        {bgGradient}
      </>
    );
  }

  // Solid background color
  color = () => {
    const { styleOverride: { bgColor } } = this.props;
    return (
      <div
        className={classNames(
          { 'bg-background__color': !bgColor },
          bgColor,
          'absolute absolute-fill',
        )}
        data-testid="background-color"
      />
    );
  }

  // Image via picture component
  picture = () => {
    const { imageUrl, animate, styleOverride } = this.props;
    if (!isResizableImage(imageUrl)) {
      return '<!-- Must be resizable image -->';
    }
    return (
      <Picture
        url={imageUrl}
        flavor="fit"
        responsiveFlavors={{
          s: AIMS_FLAVORS.FIT_760w,
          m: AIMS_FLAVORS.FIT_1000w,
          xl: AIMS_FLAVORS.FIT_1500w,
        }}
        className={classNames(
          'bg-background__picture',
          styleOverride.bgPicture,
          'absolute absolute-fill overflow-hidden',
          {
            'bg-background__animate': animate,
          },
        )}
      />
    );
  };

  // Image via styled background image
  background = () => {
    const { imageUrl, animate, styleOverride } = this.props;
    return (
      <div
        className={classNames(
          'bg-background__picture',
          styleOverride.bgPicture,
          'w-100 h-100',
          {
            'bg-background__picture': animate,
          },
        )}
        style={{ backgroundImage: `url('${imageUrl}')` }}
        data-testid="background-image"
      />
    );
  };

  render() {
    const {
      children, useBackground, styleOverride,
    } = this.props;

    return (
      <div
        data-testid="background"
        className={classNames(
          block,
          'bg-background__wrapper',
          styleOverride.bgWrapper,
          {
            'bg-use-background': useBackground,
          },
        )}
      >
        {this.getBackground()}
        {!!children && (
          <div
            className={classNames(
              styleOverride.bgContent,
              {
                'relative df flex-column justify-center': useBackground,
              },
            )}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default Background;
