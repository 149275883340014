import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {() => void} PlayHandler
 */

/**
 * @typedef {() => void} PauseHandler
 */

/**
 * @typedef {(volume: number) => void} SetVolumeHandler
 */

/**
 * @typedef {(muted: boolean) => void} SetMuteHandler
 */

/**
 * @typedef {(seekTime: number) => void} SeekToHandler
 */

/**
 * @typedef {(options?: object) => Promise<undefined>} ToggleFullscreenHandler
 */

/**
 * @typedef {(subtitleTrackId: number) => void} EnableSubtitlesHandler
 */

/**
 * @typedef {() => void} DisableSubtitlesHandler
 */

/**
 * @
 * @typedef {object} VideoController
 * @property {PlayHandler} play
 * @property {PauseHandler} pause
 * @property {SetVolumeHandler} setVolume
 * @property {SetMuteHandler} setMute
 * @property {SeekToHandler} seekTo
 * @property {ToggleFullscreenHandler} toggleFullscreen
 * @property {EnableSubtitlesHandler} enableSubtitles
 * @property {DisableSubtitlesHandler} disableSubtitles
 *
 * @property {(handler: PlayHandler) => void} registerPlayHandler
 * @property {(handler: PauseHandler) => void} registerPauseHandler
 * @property {(handler: SetVolumeHandler) => void} registerSetVolumeHandler
 * @property {(handler: SetMuteHandler) => void} registerSetMuteHandler
 * @property {(handler: SeekToHandler) => void} registerSeekToHandler
 * @property {(handler: ToggleFullscreenHandler) => void} registerToggleFullscreenHandler
 * @property {(handler: EnableSubtitlesHandler) => void} registerEnableSubtitlesHandler
 * @property {(handler: DisableSubtitlesHandler) => void} registerDisableSubtitlesHandler
 */

/**
 * @exports VideoContainer/videoController
 */

/**
 * @param {object} [options]
 * @param {ToggleFullscreenHandler} [options.toggleFullscreenHandler]
 * @returns {VideoController}
 */
export function getController({
  toggleFullscreenHandler = async () => {},
} = {}) {
  let play = () => {};
  let pause = () => {};
  let setVolume = () => {};
  let setMute = () => {};
  let seekTo = () => {};
  let enableSubtitles = () => {};
  let disableSubtitles = () => {};
  let toggleFullscreen = toggleFullscreenHandler;

  /**
   * @const {VideoController}
   */
  const controller = {
    // init action handlers
    play: () => play(),
    pause: () => pause(),
    setVolume: (...args) => setVolume(...args),
    setMute: (...args) => setMute(...args),
    seekTo: (...args) => seekTo(...args),
    enableSubtitles: (...args) => enableSubtitles(...args),
    disableSubtitles: () => disableSubtitles(),
    toggleFullscreen: (...args) => toggleFullscreen(...args),

    // register
    registerPlayHandler: (handler) => {
      play = handler;
    },
    registerPauseHandler: (handler) => {
      pause = handler;
    },
    registerSetVolumeHandler: (handler) => {
      setVolume = handler;
    },
    registerSetMuteHandler: (handler) => {
      setMute = handler;
    },
    registerSeekToHandler: (handler) => {
      seekTo = handler;
    },
    registerEnableSubtitlesHandler: (handler) => {
      enableSubtitles = handler;
    },
    registerDisableSubtitlesHandler: (handler) => {
      disableSubtitles = handler;
    },
    registerToggleFullscreenHandler: (handler) => {
      toggleFullscreen = handler;
    },
  };

  return controller;
}

/**
 * exported for testing
 * @package
 */
export const VideoControllerContext = createContext(null);
VideoControllerContext.displayName = 'VideoControllerContext';

/**
 * @returns {VideoController}
 */
export function useVideoController() {
  const videoController = useContext(VideoControllerContext);

  if (!videoController) {
    throw new Error('useVideoController called without a VideoControllerContext.Provider');
  }

  return videoController;
}

export function VideoControllerProvider({
  children,
  toggleFullscreenHandler,
}) {
  const { current: videoController } = useRef(getController({ toggleFullscreenHandler }));

  return (
    <VideoControllerContext.Provider value={videoController}>
      {children}
    </VideoControllerContext.Provider>
  );
}

VideoControllerProvider.propTypes = {
  children: PropTypes.node,
  toggleFullscreenHandler: PropTypes.func,
};

VideoControllerProvider.defaultProps = {
  children: undefined,
  toggleFullscreenHandler: undefined,
};
