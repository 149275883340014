import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const SubtitlesEnabledContext = createContext();
SubtitlesEnabledContext.displayName = 'SubtitlesEnabledContext';

export function useSubtitlesEnabled() {
  const subtitlesEnabled = useContext(SubtitlesEnabledContext);

  return subtitlesEnabled;
}

/**
 * exported for testing
 * @package
 */
export const SetSubtitlesEnabledContext = createContext();
SetSubtitlesEnabledContext.displayName = 'SetSubtitlesEnabledContext';

export function useSetSubtitlesEnabled() {
  const setSubtitlesEnabled = useContext(SetSubtitlesEnabledContext);

  // double equal to check for undefined|null
  if (setSubtitlesEnabled == null) {
    throw new Error('useSetSubtitlesEnabled called without a SetSubtitlesEnabledContext.Provider');
  }

  return setSubtitlesEnabled;
}

export const SubtitlesContext = createContext();
SubtitlesContext.displayName = 'SubtitlesContext';

export function useSubtitles() {
  const subtitles = useContext(SubtitlesContext);

  return subtitles;
}

export const SetSubtitlesContext = createContext();
SetSubtitlesContext.displayName = 'SetSubtitlesContext';

export function useSetSubtitles() {
  const setSubtitles = useContext(SetSubtitlesContext);

  if (!setSubtitles) {
    throw new Error('useSetSubtitles called without a SetSubtitlesContext.Provider');
  }

  return setSubtitles;
}

export function VideoSubtitlesProvider({ children }) {
  const [subtitles, setSubtitles] = useState(false);
  const [subtitlesEnabled, setSubtitlesEnabled] = useState(false);

  return (
    <SetSubtitlesContext.Provider value={setSubtitles}>
      <SetSubtitlesEnabledContext.Provider value={setSubtitlesEnabled}>
        <SubtitlesEnabledContext.Provider value={subtitlesEnabled}>
          <SubtitlesContext.Provider value={subtitles}>
            {children}
          </SubtitlesContext.Provider>
        </SubtitlesEnabledContext.Provider>
      </SetSubtitlesEnabledContext.Provider>
    </SetSubtitlesContext.Provider>
  );
}

VideoSubtitlesProvider.propTypes = {
  children: PropTypes.node,
};

VideoSubtitlesProvider.defaultProps = {
  children: undefined,
};
