import i18next from 'i18next';

/**
 * Prepare/Get Deep Linking Object
 *
 * @param content
 * @returns boolean|null}
 */
export const isDeepLink = (content) => {
  // Check if video first
  if (content && content.type === 'video') {
    // check if is tveEpisode and Telemundo
    if (
      content.item
      && content.item.videoType === 'tveEpisode'
      && content.item.publisher.name === 'telemundo'
    ) {
      return true;
    }
  }

  return null;
};

/**
 * Creating BranchSDK Long Link
 *
 * @param content
 * @returns {string}
 */
export const getLongLink = (content) => {
  // BranchSDK params
  const branchSDK = {
    baseUrl: 'https://telemundo.app.link',
    campaign: 'tlmddotcom',
    channel: 'tlmddotcom',
    feature: 'marketing',
    canonicalURL: content.item.url?.canonical,
    ios_deeplink_path: content.item.mpxMetadata && `video/${content.item.mpxMetadata.guid}`,
    android_deeplink_path: content.item.mpxMetadata && `video/${content.item.mpxMetadata.guid}`,
    desktop_url: content.item.url?.primary,
  };

  return `${branchSDK.baseUrl}/?~campaign=${branchSDK.campaign}&~channel=${
    branchSDK.channel
  }&feature=${branchSDK.feature}&canonicalUrl=${encodeURI(
    branchSDK.canonicalURL,
  )}&$ios_deeplink_path=${branchSDK.ios_deeplink_path}&$android_deeplink_path=${
    branchSDK.android_deeplink_path
  }&$desktop_url=${encodeURI(branchSDK.desktop_url)}`;
};

/**
 * Prepare/Get Deep Linking Object
 *
 * @param content
 * @param packageType
 * @param isMobile
 * @returns {{text: string, url: (*|null)}|boolean|null}
 */
export const getDeepLink = (content, packageType = '', isMobile = false) => {
  // Check if video first
  if (isDeepLink(content)) {
    // Packages
    const packages = [
      'oneUp',
      'twoUp',
      'threeUp',
      'fourUp',
      'sevenUp',
      'straightUp',
      'bacon',
      'canonicalPkg',
    ];

    // Checking for specific components
    if (packageType && packages.includes(packageType)) {
      // Branch
      const longLink = getLongLink(content);
      // Prepare tveEpisode payload
      return {
        url: isMobile ? longLink : content.item.url?.primary,
        urlCanonical: content.item.url?.canonical,
        text: isMobile
          ? i18next.t('Watch in Telemundo App')
          : i18next.t('Watch in NBC'),
        episode:
          content.item.partOfEpisode
          && `${
            i18next.t('Episode') + content.item.partOfEpisode.episodeNumber
          } / `,
        season:
          content.item.partOfSeason
          && `${i18next.t('Season') + content.item.partOfSeason.seasonNumber} / `,
        datePublished: content.item.datePublished,
      };
    }
  }

  return null;
};
