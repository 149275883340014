import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { stub as $t } from '@nbcnews/analytics-framework';

import { content as contentPropType } from 'lib/CustomPropTypes';
import { getTeasePicture } from 'lib/teaseUtils';
import { parsePrice } from 'lib/productOfferPriceHelpers';
import { VerticalContext } from 'lib/ContextTypes';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { Save } from 'components/SocialShareMenu/Save';
import styles from './styles.module.scss';

const TEASE_SIZES = {
  one: 'one',
  two: 'two',
};

const TEASE_SIZE_TO_IMAGE_SIZES = {
  [TEASE_SIZES.one]: {
    s: AIMS_FLAVORS.FOCAL_240x240,
  },
  [TEASE_SIZES.two]: {
    s: AIMS_FLAVORS.FOCAL_440x220,
  },
};

const TEASE_SIZE_TO_ASPECT_RATIO = {
  [TEASE_SIZES.one]: 'ASPECT_1X1',
  [TEASE_SIZES.two]: 'ASPECT_2X1',
};

/* eslint-disable max-len */
const FALLBACK_IMG_URL_MAP = {
  [TEASE_SIZES.two]: {
    better:
    'https://media4.s-nbcnews.com/i/newscms/2019_23/2888161/2x1_better_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    globalcitizen:
    'https://media1.s-nbcnews.com/i/newscms/2019_23/2888166/2x1_global-citizen_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    mach: 'https://media3.s-nbcnews.com/i/newscms/2019_23/2888181/2x1_mach_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    msnbc:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888186/2x1_msnbc_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    news: 'https://media1.s-nbcnews.com/i/newscms/2019_23/2888216/2x1_nbcnews_fallback_image_0_cbf07ab7dd92887d74bd5e4ed1bde2e5.jpg',
    select:
    'https://media4.s-nbcnews.com/i/newscms/2021_38/3507110/fallback_2_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888206/2x1_think_fallback_image_0_a5e44c6d5a0a2527b7c92c966f3fb003.jpg',
    today:
      'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
  [TEASE_SIZES.one]: {
    better:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507103/fallback_1_1_better_b61f24cd574be68f4e1d2ac299748364.png',
    globalcitizen:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507104/fallback_1_1_globalcitizen_b61f24cd574be68f4e1d2ac299748364.png',
    msnbc:
      'https://media1.s-nbcnews.com/i/newscms/2021_38/3507106/fallback_1_1_msnbc_b61f24cd574be68f4e1d2ac299748364.png',
    news: 'https://media2.s-nbcnews.com/i/newscms/2021_38/3507107/fallback_1_1_nbc_b61f24cd574be68f4e1d2ac299748364.png',
    select:
      'https://media4.s-nbcnews.com/i/newscms/2021_38/3507108/fallback_1_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507109/fallback_1_1_think_b61f24cd574be68f4e1d2ac299748364.png',
    today:
    'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
};
  /* eslint-enable max-len */

function getHeadline({
  headlineText,
  url,
  target,
  product,
}) {
  return (
    <h3
      className={styles.baconCardsWidthByOneHeader}
      data-testid="x-by-one__headline__header"
    >
      <Link
        to={url}
        data-testid="x-by-one__headline__link"
        data-product-id={product?.id}
        target={target}
        onClick={() => {
          $t('track', 'mbt_bacon_click', {
            action: 'itemClick',
            name: product?.name,
            listPrice: product?.offers[0]?.prices[0]?.price,
            seller: product?.offers[0]?.seller?.name,
          });
        }}
        rel="sponsored"
      >
        {headlineText}
      </Link>
    </h3>
  );
}
/**
 * This is a workaround for inconsistent behavior across browsers using text decoration underline with line clamp.
 * See https://github.com/nbcnews/nextjs-ramen/pull/7040
 * @param {string} name
 */
function truncateLongSellerName(name) {
  const MAX_SELLER_NAME_LENGTH = 30;

  return name?.length > MAX_SELLER_NAME_LENGTH
    ? `${name.slice(0, MAX_SELLER_NAME_LENGTH)}...`
    : name;
}

export const AdditionalSellerByOne = (props) => {
  const {
    card: {
      computedValues = {},
      id,
      isLazyLoaded = true,
      item: canonicalItem,
      type,
    } = {},
    card = {},
    shouldShowDescription,
    size,
    width,
  } = props;

  const item = canonicalItem || {};
  // ignore sorting logic for offers while SOVRN data
  // is still being investigated, use sort order from CMS
  // const productOffers = getProductOffers(card.item || []);
  const productOffers = card.item?.offers.filter((i) => i.seller) || [];
  const firstProductOffer = productOffers[0];
  const {
    description: {
      primary: description = '',
    } = {},
  } = item;

  const {
    headline: headlineText = '',
    dek,
  } = computedValues;

  const vertical = useContext(VerticalContext);

  const target = '_blank';

  const sizes = TEASE_SIZE_TO_IMAGE_SIZES[size];

  const height = width / (size === 'two' ? 2 : 1);

  const computedTeasePicture = getTeasePicture(
    computedValues,
    FALLBACK_IMG_URL_MAP[size],
    vertical,
    TEASE_SIZE_TO_ASPECT_RATIO[size],
  );
  const firstProductURL = firstProductOffer?.externalUrl;
  const firstProductOfferPrice = firstProductOffer?.prices.find((p) => p?.type === 'SALE' || p?.type === 'LIST');
  const firstProductSellerName = firstProductOffer?.seller?.name;
  return (
    <div
      className={styles.baconCardsWidthByOne}
      data-testid="additionalSellerByOne"
      data-contentid={id}
    >
      <div className={styles.baconCardsWidthByOneTeasePictureLinkWrapper}>
        <Link
          to={firstProductURL}
          data-product-id={canonicalItem?.id}
          data-testid="x-by-one__tease-picture__link"
          target={target}
          onClick={() => {
            $t('track', 'mbt_bacon_click', {
              action: 'itemClick',
              name: card.item?.name,
              listPrice: firstProductOfferPrice?.price,
              seller: firstProductSellerName,
            });
          }}
          rel="sponsored"
        >
          <TeasePicture
            computedValues={computedTeasePicture}
            height={height}
            lazyload={isLazyLoaded}
            responsiveFlavors={sizes}
            type={type}
            width={width}
          />
        </Link>
        <Save
          contentId={item.id}
          contentType={item.type}
          options={{ isThumbnail: true, showCTA: true }}
        />
      </div>
      {getHeadline({
        headlineText,
        url: firstProductURL,
        target,
        product: card.item,
        vertical,
      })}
      {shouldShowDescription && <p className={styles.description}>{dek || description}</p>}
      {productOffers.map((offer) => {
        const {
          prices = [], price: listPrice,
        } = offer;
        const seller = offer.seller || {};
        const sellerDisplayName = truncateLongSellerName(seller.name);

        if (!seller) return null;
        if (prices.length > 1) {
          const salePriceObj = prices.find((p) => (p.type === 'SALE'));
          const parsedSalePrice = parsePrice(salePriceObj?.price);
          const parsedListPrice = parsePrice(listPrice);
          const shouldShowListAndSalePrice = parsedSalePrice !== parsedListPrice;
          return (
            <Link
              to={offer.externalUrl}
              key={`${seller.id}-${seller.name}`}
              data-product-id={card.item.id}
              target="_blank"
              data-testid="x-by-one__saleprice__link"
              onClick={() => {
                $t('track', 'mbt_bacon_click', {
                  action: 'itemClick',
                  name: card.item?.name,
                  listPrice: salePriceObj?.price,
                  seller: seller?.name,
                });
              }}
              rel="sponsored"
            >
              <div className={styles.productSellerWrapper}>
                <div className={styles.priceWrapper}>
                  {shouldShowListAndSalePrice ? (
                    <>
                      <p className={`${styles.price} ${styles.newPrice}`}>
                        {`$${salePriceObj?.price}`}
                      </p>
                      <p className={styles.previousPrice}>{`$${listPrice}`}</p>
                    </>
                  ) : (
                    <p className={styles.price}>{`$${listPrice}`}</p>
                  )}
                </div>
                <p className={styles.seller}>{sellerDisplayName}</p>
              </div>
            </Link>
          );
        }

        return (
          <Link
            to={offer.externalUrl}
            target="_blank"
            key={`${seller.id}-${seller.name}`}
            data-product-id={card.item.id}
            data-testid="x-by-one__listprice__link"
            onClick={() => {
              $t('track', 'mbt_bacon_click', {
                action: 'itemClick',
                name: card.item?.name,
                listPrice,
                seller: seller?.name,
              });
            }}
            rel="sponsored"
          >
            <div
              className={styles.productSellerWrapper}
              data-testid="additional-seller-price"
            >
              <div className={styles.priceWrapper}>
                <p className={styles.price}>{`$${listPrice}`}</p>
              </div>
              <p className={styles.seller}>{sellerDisplayName}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

AdditionalSellerByOne.propTypes = {
  card: contentPropType,
  shouldShowDescription: PropTypes.bool,
  size: PropTypes.oneOf([TEASE_SIZES.one, TEASE_SIZES.two]),
  width: PropTypes.number.isRequired,
};

AdditionalSellerByOne.defaultProps = {
  card: {},
  shouldShowDescription: false,
  size: TEASE_SIZES.two,
};
