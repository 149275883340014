import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import screenfull from 'screenfull';

import { GridStack } from 'components/GridStack';

import { VideoControllerProvider } from './context/videoController';
import { VideoIsAdPlayingProvider } from './context/state/isAdPlaying';
import { VideoIsPausedProvider } from './context/state/isPaused';
import { VideoVolumeProvider } from './context/state/volume';
import { VideoTimeProvider } from './context/state/time';
import { IsFullscreenProvider } from './context/state/isFullscreen';
import { IsUIVisibleProvider } from './context/state/isUIVisible';
import { VideoSubtitlesProvider } from './context/state/subtitles';
import { VideoSubtitleTrackProvider } from './context/state/subtitleTrack';
import { VideoIsReadyProvider } from './context/state/isReady';
import { InactivityMonitorUpdate } from './InactivityMonitorUpdate';

import styles from './styles.module.scss';
import { StickyPipControl } from './StickyPipControl/index';
import { Subtitles } from './Subtitles/index';

export const asyncToggleFullScreen = async (videoContainerRef) => {
  if (screenfull.isEnabled) {
    const coreVideoContainer = videoContainerRef?.current?.querySelector('#core-video');
    const iFrameDocument = coreVideoContainer?.querySelector('iframe')?.contentWindow?.document;

    const isSupportingPictureInPicture = typeof iFrameDocument !== 'undefined' && 'pictureInPictureEnabled' in iFrameDocument;
    if (isSupportingPictureInPicture && iFrameDocument?.pictureInPictureElement) {
      await iFrameDocument?.exitPictureInPicture();
    }
    screenfull.toggle(videoContainerRef?.current);
  }
};

export function VideoContainer({ children, stickyEnabled }) {
  const videoContainerRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
      return () => {
        screenfull.off('change', () => {});
      };
    }
    return null;
  }, []);

  const toggleFullscreen = useCallback(() => asyncToggleFullScreen(videoContainerRef), []);

  return (
    <VideoControllerProvider
      toggleFullscreenHandler={toggleFullscreen}
    >
      <VideoIsReadyProvider>
        <VideoIsAdPlayingProvider>
          <VideoIsPausedProvider>
            <VideoVolumeProvider>
              <VideoTimeProvider>
                <IsFullscreenProvider value={isFullscreen}>
                  <VideoSubtitleTrackProvider>
                    <VideoSubtitlesProvider>
                      <IsUIVisibleProvider videoContainerRef={videoContainerRef}>
                        {({ isVisible }) => {
                          const content = (
                            <GridStack
                              className={classNames(styles.videoContainer, {
                                [styles.isUIVisible]: isVisible,
                              })}
                              ref={videoContainerRef}
                            >
                              {children}
                              <Subtitles />
                              <InactivityMonitorUpdate isStickyEnabled={stickyEnabled} />
                            </GridStack>
                          );

                          return (
                            <div
                              className={classNames(styles.aspectRatioContainer, {
                                [styles.placeholder]: !stickyEnabled,
                              })}
                            >
                              {stickyEnabled ? (
                                <StickyPipControl>{content}</StickyPipControl>
                              ) : (
                                content
                              )}
                            </div>
                          );
                        }}
                      </IsUIVisibleProvider>
                    </VideoSubtitlesProvider>
                  </VideoSubtitleTrackProvider>
                </IsFullscreenProvider>
              </VideoTimeProvider>
            </VideoVolumeProvider>
          </VideoIsPausedProvider>
        </VideoIsAdPlayingProvider>
      </VideoIsReadyProvider>
    </VideoControllerProvider>
  );
}

VideoContainer.propTypes = {
  children: PropTypes.node,
  stickyEnabled: PropTypes.bool,
};

VideoContainer.defaultProps = {
  children: undefined,
  stickyEnabled: false,
};
