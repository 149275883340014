import React from 'react';
import { useIsFullscreen } from 'components/VideoContainer/context/state/isFullscreen';
import { useVideoController } from 'components/VideoContainer/context/videoController';
import styles from '../styles.module.scss';

export const FullScreenButton = () => {
  const videoController = useVideoController();
  const isFullscreen = useIsFullscreen();
  return (
    <button
      key="toggleFullscreen"
      type="button"
      className={styles.btnFullScreen}
      data-test="video-toggle-fullscreen"
      data-testid="video-toggle-fullscreen"
      onClick={() => videoController.toggleFullscreen()}
    >
      {isFullscreen
        ? <span className="icon icon-full-screen-close-alt" data-test="video-close-fullscreen" data-testid="video-close-fullscreen" />
        : <span className="icon icon-full-screen-alt" data-test="video-open-fullscreen" data-testid="video-open-fullscreen" />}
    </button>
  );
};
