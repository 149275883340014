import React from 'react';

const NewsNowSVG = () => {
  const NEWS_NOW_SVG_PATH = [{
    d: 'M143.711 12.9351H106.432V15.7204H143.711V12.9351Z',
    fill: 'currentColor',
  }, {
    d: 'M138.308 11.0828H141.078L143.924 0.253418H141.218L139.725 6.7182H139.678L138.044 0.253418H135.417L133.77 6.7182H133.708L132.215 0.253418H129.276L132.114 11.0828H135.005L136.655 4.66263H136.747L138.308 11.0828Z',
    fill: 'currentColor',
  }, {
    d: 'M120.534 5.67944C120.534 3.79098 121.713 2.45958 123.394 2.45958C125.074 2.45958 126.254 3.76034 126.254 5.67944C126.254 7.59855 125.072 8.89651 123.394 8.89651C121.716 8.89651 120.534 7.59576 120.534 5.67944ZM123.394 11.3253C126.814 11.3253 129.206 8.89652 129.206 5.67944C129.206 2.46237 126.828 0.0307617 123.394 0.0307617C119.959 0.0307617 117.578 2.45958 117.578 5.67944C117.578 8.8993 119.956 11.3253 123.394 11.3253V11.3253Z',
    fill: 'currentColor',
  }, {
    d: 'M113.118 11.0827H116.427V0.250488H113.625V6.71528H113.564L109.74 0.250488H106.429V11.0827H109.23V4.61234H109.294L113.118 11.0827Z',
    fill: 'currentColor',
  }, {
    d: 'M93.1764 3.4594C93.1764 1.43446 94.8964 0 97.608 0C99.9219 0 101.706 1.11414 102.169 2.87169L99.6474 3.4594C99.3672 2.60987 98.6557 2.15308 97.6613 2.15308C96.7368 2.15308 96.0757 2.54581 96.0757 3.26721C96.0757 5.42029 102.423 3.07224 102.423 7.70427C102.423 9.85734 100.572 11.2946 97.9414 11.2946C94.9664 11.2946 93.1147 9.98825 92.5181 7.70427L95.0981 7.31432C95.2562 7.86892 95.5951 8.35559 96.0616 8.69791C96.5281 9.04022 97.0957 9.21879 97.6753 9.20557C98.7958 9.20557 99.5269 8.68471 99.5269 7.96609C99.5353 5.7406 93.1764 8.09143 93.1764 3.4594Z',
    fill: 'currentColor',
  }, {
    d: 'M85.7161 4.61826H85.6489L84.0157 11.083H81.1051L78.2422 0.248047H81.22L82.7411 6.70726H82.8055L84.4583 0.248047H87.1055L88.7583 6.70726H88.8227L90.3439 0.248047H93.0555L90.2122 11.083H87.4361L85.7161 4.61826Z',
    fill: 'currentColor',
  }, {
    d: 'M77.4499 0.248047V2.53203H71.7633V4.42328H76.2453V6.70726H71.7633V8.79627H77.5815V11.083H68.9199V0.248047H77.4499Z',
    fill: 'currentColor',
  }, {
    d: 'M60.5243 4.61826L60.4571 11.083H57.6138V0.248047H60.9193L64.7543 6.70726H64.8215V0.248047H67.6649V11.083H64.3593L60.5243 4.61826Z',
    fill: 'currentColor',
  }, {
    d: 'M42.5679 5.67658C42.5679 2.35087 44.8818 0.0668945 48.2545 0.0668945C50.899 0.0668945 52.8823 1.43728 53.6779 3.78532L50.9662 4.30619C50.7735 3.76474 50.4145 3.29724 49.94 2.96983C49.4654 2.64242 48.8993 2.47169 48.3218 2.48178C46.6018 2.48178 45.5429 3.72126 45.5429 5.67658C45.5429 7.63189 46.6634 8.87416 48.3218 8.87416C48.8996 8.88405 49.466 8.71291 49.9406 8.38498C50.4152 8.05704 50.774 7.58894 50.9662 7.04697L53.6106 7.57062C52.7506 9.98273 50.899 11.289 48.2545 11.289C44.8818 11.289 42.5679 8.941 42.5679 5.67658Z',
    fill: 'currentColor',
  }, {
    d: 'M32.4521 0.248047H37.741C40.1221 0.248047 41.6433 1.16164 41.6433 3.24786C41.6452 3.75624 41.4774 4.25089 41.1661 4.65418C40.8548 5.05747 40.4177 5.34658 39.9232 5.47614V5.5402C40.9821 5.73796 41.9066 6.52064 41.9066 8.01916C41.9066 10.1722 40.3855 11.083 38.0072 11.083H32.4521V0.248047ZM35.3627 4.49012H37.2788C38.3993 4.49012 38.7999 4.2283 38.7999 3.50968C38.7999 2.79107 38.3377 2.53203 37.2788 2.53203H35.3627V4.49012ZM35.3627 6.51229V8.79627H37.346C38.5366 8.79627 39.0632 8.4676 39.0632 7.68214C39.0632 6.89667 38.5366 6.568 37.346 6.568H35.3627V6.51229Z',
    fill: 'currentColor',
  }, {
    d: 'M24.054 4.61826L23.984 11.083H21.1406V0.248047H24.449L28.284 6.70726H28.3484V0.248047H31.1917V11.083H27.8862L24.054 4.61826Z',
    fill: 'currentColor',
  }, {
    d: 'M16.9526 7.11688C16.5767 7.10716 16.2056 7.20323 15.8822 7.39403L10.6504 11.0274H16.9526C17.473 11.0274 17.9721 10.8219 18.3401 10.456C18.7081 10.09 18.9149 9.59378 18.9149 9.07631C18.9149 8.55884 18.7081 8.06257 18.3401 7.69667C17.9721 7.33076 17.473 7.12519 16.9526 7.12519V7.11688Z',
    fill: '#0DB14B',
  }, {
    d: 'M10.8291 10.1962L16.5432 6.29952C16.7602 6.16158 16.9478 5.98248 17.0953 5.77245C17.2427 5.56242 17.3471 5.32557 17.4025 5.07542C17.4579 4.82527 17.4632 4.56673 17.4181 4.31455C17.373 4.06237 17.2784 3.82147 17.1397 3.60565C17.0009 3.38983 16.8208 3.20329 16.6096 3.05669C16.3983 2.91008 16.1601 2.80626 15.9086 2.75119C15.657 2.69611 15.3969 2.69085 15.1433 2.73569C14.8897 2.78054 14.6474 2.87463 14.4304 3.01257C14.109 3.20856 13.8499 3.49107 13.6833 3.82738L10.8291 10.1962Z',
    fill: '#0089CF',
  }, {
    d: 'M12.5989 0.249348C12.8333 0.357094 13.0439 0.510047 13.2185 0.699339C13.393 0.88863 13.528 1.1105 13.6156 1.35206C13.7033 1.59363 13.7418 1.85008 13.729 2.10657C13.7162 2.36306 13.6523 2.61446 13.541 2.84621L10.8206 8.79378L9.93701 3.17879C9.90412 3.02045 9.93406 2.85561 10.0206 2.71873C10.238 2.3917 10.7843 2.13396 10.7815 2.09793C10.7788 2.0619 10.7286 1.98984 10.5028 1.98984H9.78369C9.79649 1.71105 9.86569 1.43769 9.98716 1.1861C10.0955 0.952999 10.2494 0.743609 10.4397 0.570069C10.6301 0.396529 10.8533 0.262295 11.0962 0.175156C11.3392 0.0880165 11.5971 0.04971 11.855 0.0624465C12.113 0.0751829 12.3658 0.138705 12.5989 0.249348V0.249348Z',
    fill: '#645FAA',
  }, {
    d: 'M6.41914 0.249348C6.1847 0.357094 5.97407 0.510047 5.79954 0.699339C5.625 0.88863 5.49 1.1105 5.40236 1.35206C5.31472 1.59363 5.27621 1.85008 5.28902 2.10657C5.30183 2.36306 5.36574 2.61446 5.47701 2.84621L8.19747 8.79378L9.22319 2.27251C9.26287 1.89961 9.19624 1.52313 9.03088 1.1861C8.92252 0.952999 8.76869 0.743609 8.57831 0.570069C8.38793 0.396529 8.16478 0.262295 7.92183 0.175156C7.67888 0.0880165 7.42095 0.04971 7.163 0.0624465C6.90504 0.0751829 6.65221 0.138705 6.41914 0.249348V0.249348Z',
    fill: '#C9234A',
  }, {
    d: 'M2.46669 6.2992L8.18075 10.1959L5.31535 3.82151C5.14952 3.48468 4.89036 3.20198 4.56836 3.0067C4.12998 2.72812 3.59826 2.63411 3.09017 2.74534C2.58207 2.85656 2.13921 3.16392 1.85903 3.5998C1.57886 4.03567 1.48432 4.56437 1.59619 5.06957C1.70805 5.57476 2.01715 6.01507 2.45553 6.29365L2.46669 6.2992Z',
    fill: '#F37021',
  }, {
    d: 'M2.06533 7.11707C1.5449 7.11707 1.04575 7.32263 0.677748 7.68853C0.309746 8.05443 0.103027 8.55071 0.103027 9.06817C0.103027 9.58564 0.309746 10.0819 0.677748 10.4478C1.04575 10.8137 1.5449 11.0193 2.06533 11.0193H8.36752L3.13566 7.40252C2.81314 7.20881 2.44206 7.10985 2.06533 7.11707Z',
    fill: '#FDB913',
  }];

  return (
    <svg width="144" height="16" viewBox="0 0 144 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>NBC News Now</title>
      <g clipPath="url(#clip0_3686_260016)">
        {NEWS_NOW_SVG_PATH.map((config) => (
          <path
            key={config.d}
            d={config.d}
            fill={config.fill}
          />
        ))}
      </g>
      <defs>
        <clipPath id="clip0_3686_260016">
          <rect width="144" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export { NewsNowSVG };
