import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Link from 'components/Link';
import Head from 'next/head';
import { unibrow as UnibrowPropType } from 'lib/CustomPropTypes';
import { isBreakingNewsText } from 'lib/breakingNews';
import get from 'lodash.get';
import './styles.themed.scss';

const block = 'unibrow';

// External Link SVG Icon
const SVG = () => (
  <span className="unibrow__external-link-icon">
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="unibrow__external-link-svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M14.4 3H2.4H0V5.4V18.6V19.8V21H24L24 19.8L24 18.6L24 12.6H21.6V18.6H2.4V5.4H14.4V3ZM21.4368 7.63675L24 10.2V3H16.8L19.7397 5.93969L12.5397 13.1397L14.2368 14.8368L21.4368 7.63675Z"
        className="unibrow__external-link-path"
      />
    </svg>
  </span>
);

const Unibrow = ({
  children,
  className,
  size: HTag,
  target,
  unibrow,
  deepLink,
  enableLogos,
  rel,
  icid,
  hasDefaultTextStyle,
  testId,
}) => {
  if (!unibrow) return null;

  let deepLinkingText = null;
  let deepLinkingUrl = null;
  let elementLogo = null;
  let unibrowUrl = null;

  const unibrowLogo = get(unibrow, 'logo.logoAssets[0].mediaAsset.url.primary', false);
  const unibrowLogoUrl = get(unibrow, 'logo.url.primary', false);

  const unibrowTaxonomyPath = get(unibrow, 'taxonomyPath', false);
  const unibrowLogoTaxonomyPath = get(unibrow, 'logo.taxonomyPath', false);

  const unibrowMatch = unibrowTaxonomyPath
    && unibrowLogoTaxonomyPath
    && (unibrowTaxonomyPath === unibrowLogoTaxonomyPath);

  if (deepLink) {
    // Adding DeepLinking Text + SVG icon
    if (deepLink.text) {
      deepLinkingText = (
        <span className="unibrow__external-link-container">
          {deepLink.text}
          {SVG()}
        </span>
      );
    }
    // Adding DeepLinking URL
    deepLinkingUrl = deepLink.url;
  }

  const { t } = useTranslation();

  const unibrowText = deepLinkingText
  || unibrow.override
  || (isBreakingNewsText(unibrow.text) ? t(unibrow.text) : unibrow.text)
  || '';

  let element = (
    <span data-test="unibrow-text" data-testid="unibrow-text">
      {unibrowText}
    </span>
  );

  if (deepLinkingUrl || (unibrow?.url?.primary)) {
    unibrowUrl = deepLinkingUrl || unibrow.url.override || unibrow.url.primary;
    element = (
      <Link href={unibrowUrl} target={target} rel={rel} icid={icid}>
        {element}
      </Link>
    );
  }

  if (unibrowLogo && enableLogos) {
    elementLogo = (
      <img
        className={
          classNames(
            'unibrow-logo__image',
            {
              'unibrow-logo__image-border': enableLogos,
            },
          )
        }
        src={unibrowLogo}
        alt="unibrow"
      />
    );
  }

  if (unibrowLogo && unibrowLogoUrl && enableLogos) {
    elementLogo = (
      <Link href={unibrowLogoUrl} target={target} icid={icid}>
        {elementLogo}
      </Link>
    );
  }

  if (unibrowMatch) {
    element = null;
  }

  return (
    <>
      <Head>
        <meta name="branch:deeplink:unibrow" content={unibrowText} />
      </Head>

      <HTag
        data-testid={testId || 'unibrow-container'}
        className={
          classNames(
            block,
            'articleTitleSection',
            className,
            {
              'unibrow-logo': unibrowLogo,
              [`${block}--breaking-news`]: isBreakingNewsText(unibrow.text),
              'unibrow--default': hasDefaultTextStyle,
            },
          )
        }
      >
        {elementLogo}
        {element}
        {children}
      </HTag>
    </>
  );
};

Unibrow.propTypes = {
  unibrow: UnibrowPropType.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  size: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  deepLink: PropTypes.objectOf(PropTypes.any),
  enableLogos: PropTypes.bool,
  icid: PropTypes.string,
  hasDefaultTextStyle: PropTypes.bool,
  testId: PropTypes.string,
};

Unibrow.defaultProps = {
  className: '',
  children: null,
  size: 'h2',
  target: null,
  rel: null,
  deepLink: null,
  enableLogos: false,
  icid: null,
  hasDefaultTextStyle: false,
  testId: null,
};

export { Unibrow };
