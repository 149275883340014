import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

/**
 * exported for testing
 * @package
 */
export const VideoVolumeContext = createContext();
VideoVolumeContext.displayName = 'VideoVolumeContext';

export function useVideoVolume() {
  const videoVolume = useContext(VideoVolumeContext);

  if (!videoVolume) {
    throw new Error('useVideoVolume called without a VideoVolumeContext.Provider');
  }

  return videoVolume;
}

export const SetVideoVolumeContext = createContext();
SetVideoVolumeContext.displayName = 'SetVideoVolumeContext';

export function useSetVideoVolume() {
  const setVideoVolume = useContext(SetVideoVolumeContext);

  if (!setVideoVolume) {
    throw new Error('useSetVideoVolume called without a SetVideoVolumeContext.Provider');
  }

  return setVideoVolume;
}

export function VideoVolumeProvider({ children }) {
  const [videoVolume, setVideoVolume] = useState({
    volume: 1,
    muted: true,
  });

  return (
    <SetVideoVolumeContext.Provider value={setVideoVolume}>
      <VideoVolumeContext.Provider value={videoVolume}>
        {children}
      </VideoVolumeContext.Provider>
    </SetVideoVolumeContext.Provider>
  );
}

VideoVolumeProvider.propTypes = {
  children: PropTypes.node,
};

VideoVolumeProvider.defaultProps = {
  children: undefined,
};
