import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import { ButtonTilt } from 'components/ButtonTilt';
import { VerticalContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { BUTTON_TYPE } from 'lib/brandFeatures';

/**
 * Helper component to switch between different button styles
 * @param {string} [buttonType] - override the vertical default for type of component to use
 * @param {object} [props] - the remaining props to pass down to the styled button
 */
const Button = ({ buttonType, ...props }) => {
  const vertical = useContext(VerticalContext);
  const defaultButtonType = getFeatureConfigForBrand(
    BUTTON_TYPE,
    vertical,
  );
  const type = buttonType || defaultButtonType;

  let Component = ButtonHoverAnimation;

  if (type === 'button-tilt') {
    Component = ButtonTilt;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component data-test={type} buttonType={type} {...props} />;
};

Button.propTypes = {
  buttonType: PropTypes.string,
};

Button.defaultProps = {
  buttonType: null,
};

export { Button };
