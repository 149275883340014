import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import {
  useIsUIVisible,
  useSetIsUIVisible,
} from 'components/VideoContainer/context/state/isUIVisible';

import styles from './styles.module.scss';

export function Backdrop() {
  const backdropRef = useRef();
  const isUIVisible = useIsUIVisible();
  const { showUIThenHide, hideUIImmediately } = useSetIsUIVisible();

  useEffect(() => {
    // manually adding event listener via ref and `addEventListener` here due to needing to call
    // `e.preventDefault()` in the listener. React adds touch event listeners as "passive" which do
    // not allow using `preventDefault`. see react gh issue here:
    // https://github.com/facebook/react/issues/19651

    function touchListener(e) {
      e.preventDefault();
      e.stopPropagation();

      if (isUIVisible) {
        hideUIImmediately();
        return;
      }

      showUIThenHide();
    }

    backdropRef?.current?.addEventListener?.('touchstart', touchListener);

    return () => {
      backdropRef?.current?.removeEventListener?.('touchstart', touchListener);
    };
  }, [isUIVisible]);

  return (
    <div
      className={classNames(styles.controlRackBackdrop, {
        [styles.uiVisible]: isUIVisible,
      })}
      ref={backdropRef}
    />
  );
}
