import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

/**
 * exported for testing
 * @package
 */
export const IsVideoPausedContext = createContext();
IsVideoPausedContext.displayName = 'IsVideoPausedContext';

export function useIsVideoPaused() {
  const isVideoPaused = useContext(IsVideoPausedContext);

  // double equal to check for undefined|null
  if (isVideoPaused == null) {
    throw new Error('useIsVideoPaused called without a IsVideoPausedContext.Provider');
  }

  return isVideoPaused;
}

/**
 * exported for testing
 * @package
 */
export const SetIsVideoPausedContext = createContext();
SetIsVideoPausedContext.displayName = 'SetIsVideoPausedContext';

export function useSetIsVideoPaused() {
  const setIsVideoPaused = useContext(SetIsVideoPausedContext);

  if (!setIsVideoPaused) {
    throw new Error('useSetIsVideoPaused called without a SetIsVideoPausedContext.Provider');
  }

  return setIsVideoPaused;
}

export function VideoIsPausedProvider({ children }) {
  const [isPaused, setIsPaused] = useState(true);

  return (
    <SetIsVideoPausedContext.Provider value={setIsPaused}>
      <IsVideoPausedContext.Provider value={isPaused}>
        {children}
      </IsVideoPausedContext.Provider>
    </SetIsVideoPausedContext.Provider>
  );
}

VideoIsPausedProvider.propTypes = {
  children: PropTypes.node,
};

VideoIsPausedProvider.defaultProps = {
  children: undefined,
};
