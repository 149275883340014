import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useIsMounted } from 'lib/Hooks/useIsMounted';

import { useIsVideoPaused } from './isPaused';

const HIDE_DELAY_TIME = 2 * 1000;

const IsUIVisibleContext = createContext();
IsUIVisibleContext.displayName = 'IsUIVisibleContext';

export function useIsUIVisible() {
  const isUIVisible = useContext(IsUIVisibleContext);

  // double equal to check for undefined|null
  if (isUIVisible == null) {
    throw new Error('useIsUIVisible called without a IsUIVisibleContext.Provider');
  }

  return isUIVisible;
}

const SetIsUIVisibleContext = createContext();
SetIsUIVisibleContext.displayName = 'SetIsUIVisibleContext';

export function useSetIsUIVisible() {
  const setIsUIVisible = useContext(SetIsUIVisibleContext);

  if (!setIsUIVisible) {
    throw new Error('useSetIsUIVisible called without a SetIsUIVisibleContext.Provider');
  }

  return setIsUIVisible;
}

export function IsUIVisibleProvider({ children, videoContainerRef }) {
  const isVideoPaused = useIsVideoPaused();
  const [isVisibleByInteraction, setIsVisibleByInteraction] = useState(true);
  const isMounted = useIsMounted();
  const hideTimeoutRef = useRef(null);

  const hideUIImmediately = useCallback(() => {
    if (isMounted()) {
      setIsVisibleByInteraction(false);
    }
  }, []);

  const cancelHideTimeout = useCallback(() => {
    window.clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = null;
  }, []);

  const resetHideTimeout = useCallback(() => {
    cancelHideTimeout();
    hideTimeoutRef.current = setTimeout(() => {
      hideUIImmediately();
    }, HIDE_DELAY_TIME);
  }, []);

  const showUI = useCallback(() => {
    setIsVisibleByInteraction(true);
    resetHideTimeout();
  }, []);

  useEffect(() => {
    if (isVideoPaused) {
      cancelHideTimeout();
    }

    if (!isVideoPaused) {
      resetHideTimeout();
    }
  }, [isVideoPaused]);

  useEffect(() => {
    videoContainerRef?.current?.addEventListener('mousemove', showUI);
    videoContainerRef?.current?.addEventListener('mouseleave', hideUIImmediately);

    return () => {
      videoContainerRef?.current?.removeEventListener('mousemove', showUI);
      videoContainerRef?.current?.removeEventListener('mouseleave', hideUIImmediately);
    };
  }, [showUI, hideUIImmediately, videoContainerRef?.current]);

  const setIsVisibleAPI = useMemo(() => ({
    showUIThenHide: showUI,
    hideUIImmediately,
    resetHideTimeout,
    setIsVisibleByInteraction,
  }), [showUI, hideUIImmediately, resetHideTimeout, setIsVisibleByInteraction]);

  // show UI always when paused, otherwise rely on user interaction (hover, touchstart)
  const isVisible = isVideoPaused || isVisibleByInteraction;

  return (
    <SetIsUIVisibleContext.Provider value={setIsVisibleAPI}>
      <IsUIVisibleContext.Provider value={isVisible}>
        {(
          typeof children === 'function'
            ? children({ isVisible })
            : children
        )}
      </IsUIVisibleContext.Provider>
    </SetIsUIVisibleContext.Provider>
  );
}

IsUIVisibleProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  videoContainerRef: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  }),
};

IsUIVisibleProvider.defaultProps = {
  children: undefined,
  videoContainerRef: undefined,
};
