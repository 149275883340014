import React from 'react';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import { isLengthGreaterThan8, getFontSizeFromCharLength } from 'lib/fontBasedOnTextLength';
import classNames from 'classnames';
import { ShopButtonVariation } from './ShopButtonVariation';

function ShopButton({
  additionalStyles,
  block,
  isWaffle,
  isBacon,
  listPrice,
  salePrice,
  name,
  seller,
  shouldTrackClicks,
  trackingCode,
  variation,
}) {
  const sellerFontSize = getFontSizeFromCharLength(seller);
  const bemElemVendorBtn = `${block}__vendorBtn`;
  const isLongerThan8Chars = isLengthGreaterThan8(seller);
  $t('register', trackingCode, { allowDuplicate: true });

  return (
    <div
      className={classNames('shop-button-info__shop', additionalStyles.shop)}
    >
      <button
        tabIndex={-1}
        type="button"
        className={classNames(
          bemElemVendorBtn,
          additionalStyles.vendor,
          'shop-button-info__vendor',
          {
            'shop-button-info__vendor-fit-text pl3-m':
              isLongerThan8Chars && isWaffle,
            'shop-button-info__vendor-fit-text': isLongerThan8Chars,
            'shop-button-info__bacon-button': isBacon,
            'shop-button-info__not-bacon-button': !isBacon,
            'shop-button--variation_1': variation === '1',
            'shop-button--variation_2': variation === '2',
            'shop-button--variation_3': variation === '3',
            variation,
          },
        )}
        onClick={shouldTrackClicks ? (() => {
          $t('track', trackingCode, {
            action: 'itemClick',
            name,
            listPrice,
            seller,
          });
        }) : undefined}
      >

        {variation ? (
          <ShopButtonVariation
            listPrice={listPrice}
            salePrice={salePrice}
            seller={seller}
          />
        ) : (
          <>
            <span
              className={classNames(
                additionalStyles.icon,
                'shop-button-info__icon icon icon-shop',
              )}
            />
            <span
              className={classNames(
                additionalStyles.vendorName,
                sellerFontSize,
                'shop-button-info__vendor-name',
              )}
              data-testid="vendor-name"
            >
              {seller}
            </span>
          </>
        )}
      </button>
    </div>
  );
}

ShopButton.propTypes = {
  additionalStyles: PropTypes.shape().isRequired,
  block: PropTypes.string.isRequired,
  isWaffle: PropTypes.bool.isRequired,
  isBacon: PropTypes.bool,
  listPrice: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  seller: PropTypes.string.isRequired,
  salePrice: PropTypes.string,
  shouldTrackClicks: PropTypes.bool,
  trackingCode: PropTypes.string,
  variation: PropTypes.string,
};

ShopButton.defaultProps = {
  isBacon: false,
  salePrice: null,
  shouldTrackClicks: true,
  trackingCode: 'mbt_ecommerce_shop_button',
  variation: null,
};


export default ShopButton;
