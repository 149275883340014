import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { NewsNow } from './Banners/NewsNow/index';

const paths = {
  NEWS_NOW: 'https://www.nbcnews.com/now',
};

const getBanner = (type) => {
  switch (type) {
    // placeholder for future banners, for now this defaults to News Now
    default: return {
      url: paths.NEWS_NOW,
      component: NewsNow,
    };
  }
};

function PrimaryMediaBanner({ type, theme, dataIcid }) {
  const { component: BannerContent, url } = getBanner(type);

  return (
    <a
      className={classNames(styles.PrimaryMediaBannerCta, {
        [styles.LightPrimaryMediaBannerCta]: theme === 'light',
        [styles.DarkPrimaryMediaBannerCta]: theme === 'dark',
      })}
      data-icid={dataIcid}
      data-testid="primary-media-banner"
      href={url}
    >
      <BannerContent theme={theme} />
    </a>
  );
}

PrimaryMediaBanner.propTypes = {
  type: PropTypes.string.isRequired,
  theme: PropTypes.string,
  dataIcid: PropTypes.string,
};

PrimaryMediaBanner.defaultProps = {
  theme: 'light',
  dataIcid: '',
};

export { PrimaryMediaBanner };
