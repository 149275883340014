import React from 'react';
import { useSubtitleTrack } from 'components/VideoContainer/context/state/subtitleTrack';
import { useSetSubtitlesEnabled, useSubtitlesEnabled } from 'components/VideoContainer/context/state/subtitles';
import { useVideoController } from 'components/VideoContainer/context/videoController';
import { ClosedCaption } from './icons/ClosedCaption';

import styles from './styles.module.scss';

export const SubtitlesButton = () => {
  const videoController = useVideoController();
  const subtitleTrackId = useSubtitleTrack();
  const subtitlesEnabled = useSubtitlesEnabled();
  const setSubtitlesEnabled = useSetSubtitlesEnabled();

  function disableSubtitles() {
    videoController.disableSubtitles();
    setSubtitlesEnabled(false);
  }
  function enableSubtitles(subtitleTrack) {
    videoController.enableSubtitles(subtitleTrack);
    setSubtitlesEnabled(true);
  }

  return subtitlesEnabled ? (
    <button
      type="button"
      onClick={() => disableSubtitles()}
      data-testid="disable-subtitles"
      aria-label="Toggle subtitles"
    >
      <ClosedCaption className={styles.captionOn} />
    </button>
  ) : (
    <button
      type="button"
      onClick={() => enableSubtitles(subtitleTrackId)}
      data-testid="enable-subtitles"
      aria-label="Toggle subtitles"
    >
      <ClosedCaption className={styles.captionOff} />
    </button>
  );
};
