import React from 'react';
import PropTypes from 'prop-types';
import { content as contentPropType } from 'lib/CustomPropTypes';
import { WidthByOne } from '../WidthByOne/WidthByOne';
import { AdditionalSellerByOne } from '../AdditionalSellerByOne/AdditionalSellerByOne';
import { ShopListByOne } from '../ShopListByOne/ShopListByOne';
import { isShopListByOneProduct } from '../helper';

export const OneByOne = ({
  card,
  openInNewTab,
  pkgType,
  showEyebrow,
  showTimestamp,
  width,
  indexOffset,
  totalNumberOfCards,
  shouldShowAdditionalSeller,
  shouldShowDescription,
}) => {
  if (isShopListByOneProduct(pkgType)) {
    return (
      <ShopListByOne
        card={card}
        openInNewTab={openInNewTab}
        pkgType={pkgType}
        size="one"
        width={width}
        indexOffset={indexOffset}
        totalNumberOfCards={totalNumberOfCards}
        shouldShowDescription={shouldShowDescription}
      />
    );
  }

  if (shouldShowAdditionalSeller) {
    return (
      <AdditionalSellerByOne
        card={card}
        openInNewTab={openInNewTab}
        pkgType={pkgType}
        shouldShowDescription={shouldShowDescription}
        size="one"
        width={width}
      />
    );
  }

  return (
    <WidthByOne
      card={card}
      openInNewTab={openInNewTab}
      pkgType={pkgType}
      showEyebrow={showEyebrow}
      showTimestamp={showTimestamp}
      size="one"
      width={width}
    />
  );
};


OneByOne.propTypes = {
  card: contentPropType,
  openInNewTab: PropTypes.bool,
  pkgType: PropTypes.string,
  showEyebrow: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  width: PropTypes.number,
  indexOffset: PropTypes.number,
  totalNumberOfCards: PropTypes.number,
  shouldShowAdditionalSeller: PropTypes.bool,
  shouldShowDescription: PropTypes.bool,
};

OneByOne.defaultProps = {
  card: {},
  openInNewTab: false,
  pkgType: null,
  showEyebrow: true,
  showTimestamp: false,
  // Defined and passed in Bacon.jsx, setting default for tests
  width: 260,
  indexOffset: null,
  totalNumberOfCards: null,
  shouldShowAdditionalSeller: false,
  shouldShowDescription: false,
};
