import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import './styles.themed.scss';
import { useRouter } from 'next/router';

const block = 'animated-ghost-button';

const typesRequired = [
  'button',
  'buttonWithArrow',
  'link',
  'subscribeButton',
];

const ButtonHoverAnimation = (props) => {
  const {
    additionalClasses,
    animated,
    disabled,
    hasDarkBackground,
    iconStyle,
    id,
    onClick,
    size,
    target,
    title,
    type,
    url,
    icid,
    isInverted,
    buttonType,
  } = props;

  const buttonClassString = classNames(
    {
      [`${block} ${block}--${size}`]: animated,
      [`${block}--darkBackground`]: hasDarkBackground,
      [`${block}--inverted`]: isInverted,
      disabled,
    },
    additionalClasses,
  );

  const { query: { page } = {} } = useRouter() || {};
  const formattedTitle = title.toLowerCase().replace(/[^a-z\s]/g, '').replace(/\s+/g, '-'); // title-like-this
  const dataActivityMap = page === 'recipe'
    ? `button-hover-animation-article-sidebar-${formattedTitle}`
    : undefined;

  if (type === 'link') {
    return (
      <Link
        className={buttonClassString}
        href={url}
        target={target}
        icid={icid}
        data-activity-map={dataActivityMap}
        data-testid={buttonType}
      >
        {title}
      </Link>
    );
  }

  if (onClick) {
    const children = [title];

    if (type === 'buttonWithArrow' && iconStyle) {
      children.push(<span className={iconStyle} />);
    }

    return (
      <button
        className={buttonClassString}
        disabled={disabled}
        id={id}
        onClick={onClick}
        type="button"
        data-activity-map={dataActivityMap}
        data-testid={buttonType}
      >
        {children}
      </button>
    );
  }

  return null;
};

ButtonHoverAnimation.propTypes = {
  additionalClasses: PropTypes.string,
  animated: PropTypes.bool,
  disabled: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  iconStyle: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(typesRequired),
  url: PropTypes.string,
  icid: PropTypes.string,
  isInverted: PropTypes.bool,
  buttonType: PropTypes.string,
};

ButtonHoverAnimation.defaultProps = {
  additionalClasses: null,
  animated: true,
  disabled: false,
  hasDarkBackground: false,
  iconStyle: '',
  id: null,
  onClick: null,
  size: 'normal',
  target: '_self',
  title: '',
  url: null,
  icid: null,
  type: undefined,
  isInverted: false,
  buttonType: 'button-hover-animation',
};

export default ButtonHoverAnimation;
