export const BREAKING_NEWS_FLAGS = {
  'BREAKING NEWS': 'BREAKING NEWS',
  EXCLUSIVE: 'EXCLUSIVE',
  DEVELOPING: 'DEVELOPING',
};

/**
 * Determines if search `text` is one of the `BREAKING_NEWS_FLAGS`
 * @param {*} text
 * @returns {boolean}
 */
export const isBreakingNewsText = (text) => (
  // ignoring the rule here, as it references an object literal defined in this
  // file which will always have the `hasOwnProperty` method from the Object
  // prototype
  // eslint-disable-next-line no-prototype-builtins
  BREAKING_NEWS_FLAGS.hasOwnProperty(text)
);
