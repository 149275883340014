import React from 'react';

const NewsNowUpdatedSVG = () => (
  <svg width="138.66" height="12" viewBox="0 0 327 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9709 23.3904L6.5868 15.8039C3.69036 13.8686 0.823962 15.8425 0.214637 17.8807C-0.682186 20.8887 1.32601 23.3904 4.47562 23.3904H17.9709Z" fill="#FCCC12" />
    <path d="M35.88 23.3904C39.0296 23.3904 41.0336 20.8845 40.141 17.8807C39.5317 15.8425 36.6696 13.8686 33.7688 15.8039L22.3848 23.3904H35.88Z" fill="#05AC3F" />
    <path d="M28.8204 5.73708L22.9804 19.0779L21.3112 7.48783C21.0966 5.91302 23.7785 4.94324 23.7785 4.94324C23.5339 4.39828 22.6886 4.40687 22.6886 4.40687H20.8778H20.8735C20.586 1.55763 23.1735 -0.519228 25.9669 0.115843C28.7175 0.725168 29.713 3.71172 28.8204 5.73708Z" fill="#6E55DC" />
    <path d="M19.4644 4.59143C19.885 1.64779 17.246 -0.532058 14.3925 0.115887C11.6419 0.729503 10.6464 3.71606 11.5389 5.74142L17.379 19.0822L19.4644 4.59143Z" fill="#EF1541" />
    <path d="M16.9129 21.2663L11.3088 8.49197C9.99577 5.53116 6.52004 5.16213 4.60196 6.89999C3.25028 8.12293 2.20327 11.4571 5.22415 13.4738L16.9129 21.2663Z" fill="#FF7112" />
    <path d="M35.136 13.4738C38.1569 11.457 37.1099 8.12292 35.7582 6.89569C33.8401 5.16212 30.3644 5.52685 29.0513 8.48766L23.4473 21.262L35.136 13.4738Z" fill="#069DE0" />
    <path d="M117.772 15.6579C115.52 17.5803 114.086 18.6659 110.838 18.6659H110.701C106.23 18.6659 103.462 15.1945 103.462 11.9805C103.462 8.29452 106.723 5.2951 110.727 5.2951C113.953 5.32513 115.661 7.05441 116.481 7.89116L116.61 8.0199L120.377 4.25666C119.957 3.83614 116.743 0.00427246 110.765 0.00427246C103.882 0.00427246 98.1668 5.23073 98.1367 11.9548C98.1367 18.4814 103.625 23.9696 110.701 23.9997C116.026 23.9997 118.721 21.8241 121.222 19.6829L117.772 15.6579Z" fill="black" />
    <path d="M96.0643 16.2158C96.0643 22.4163 91.0996 23.3904 87.9929 23.3904H74.7422V0.596436H87.98C92.0393 0.596436 95.2833 2.90072 95.2833 7.22178C95.2833 9.3587 93.7772 10.5387 92.6315 11.1223C94.5195 12.0663 96.0643 13.4609 96.0643 16.2158ZM79.5868 4.87459V9.18707C79.5868 9.18707 86.5425 9.18707 87.4007 9.18707C88.6923 9.18707 90.1083 8.65068 90.1083 7.04584C90.1083 5.441 89.0055 4.87459 87.2205 4.87459C86.2035 4.87459 79.5868 4.87459 79.5868 4.87459ZM90.9151 16.2158C90.9151 14.0273 89.0914 13.4609 87.3192 13.4609C85.7272 13.4609 79.5868 13.4609 79.5868 13.4609V18.9062C79.5868 18.9062 86.0619 18.9062 87.5037 18.9062C88.9455 18.9062 90.9151 18.4042 90.9151 16.2158Z" fill="black" />
    <path d="M49.1289 0.596436H54.4755L65.482 15.3404V0.596436H70.4939V23.3904H65.1473L54.1365 8.61636V23.3904H49.1289V0.596436Z" fill="black" />
    <path d="M174.81 18.5973V23.3946H155.436V0.600708H174.531V5.17064H160.289V9.18705H172.484V13.7527H160.289V18.5801L174.81 18.5973Z" fill="black" />
    <path d="M129.834 0.596436H135.181L146.187 15.3404V0.596436H151.199V23.3904H145.852L134.842 8.61636V23.3904H129.834V0.596436Z" fill="black" />
    <path d="M203.545 0.600708L199.357 15.4562L195.045 0.600708H189.857L185.549 15.4562L181.356 0.600708H176.619L182.871 23.3946H187.969L192.453 9.03686L196.933 23.3946H202.035L208.287 0.600708H203.545Z" fill="black" />
    <path d="M225.546 10.543C221.714 8.91675 214.896 10.1526 214.943 7.03727C214.96 5.8315 216.72 4.79307 219.539 4.77161C222.71 4.74587 226.726 6.43224 226.726 6.43224L229.279 2.37723C229.279 2.37723 224.087 0 219.625 0C212.819 0 209.609 2.99942 209.609 6.74119C209.609 10.5817 211.759 12.105 212.926 12.6714C216.711 14.5122 220.95 14.0102 223.238 15.1516C225.739 16.4003 224.748 19.3868 219.625 18.9663C215.857 18.6574 213.63 17.9064 210.918 16.3059L208.129 20.2193C213.403 23.4762 218.487 23.9911 219.629 23.9997C223.611 24.0211 230.069 22.9656 229.97 16.4175C229.927 13.7399 228.631 11.8518 225.546 10.543Z" fill="black" />
    <path d="M326.454 20.4552H315.881V23.3903H326.454V20.4552Z" fill="black" />
    <path d="M239.492 0.596436H244.839L255.845 15.3404V0.596436H260.857V23.3904H255.511L244.504 8.62065V23.3904H239.492V0.596436Z" fill="black" />
    <path d="M314.237 0.596436L310.045 15.4562L305.732 0.596436H300.549L296.236 15.4562L292.044 0.596436H287.307L293.559 23.3904H298.661L303.14 9.03688L307.62 23.3904H312.722L318.974 0.596436H314.237Z" fill="black" />
    <path d="M275.541 5.1578C279.312 5.1578 282.381 8.22589 282.381 11.9977C282.381 15.7695 279.312 18.8376 275.541 18.8376C271.769 18.8376 268.701 15.7695 268.701 11.9977C268.701 8.22589 271.769 5.1578 275.541 5.1578ZM275.541 0C268.915 0 263.543 5.37236 263.543 11.9977C263.543 18.623 268.915 23.9954 275.541 23.9954C282.166 23.9954 287.538 18.623 287.538 11.9977C287.538 5.37236 282.166 0 275.541 0Z" fill="black" />
  </svg>
);

export { NewsNowUpdatedSVG };
