import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/Modal';
import Breakpoints from 'lib/Breakpoints';
import styles from 'components/packages/Bacon/Cards/TwoByThree/styles.module.scss';
import './styles.themed.scss';

const block = 'card-overlay';

export class CardOverlay extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    isActive: PropTypes.bool,
    useModal: PropTypes.bool,
    additionalClasses: PropTypes.shape({ border: PropTypes.string, container: PropTypes.string }),
    activeOnHover: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    useModal: false,
    additionalClasses: {
      border: '',
      container: '',
    },
    activeOnHover: false,
    isActive: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isListening: false,
    };
  }

  componentDidUpdate() {
    const { isActive } = this.props;
    const { isListening } = this.state;
    if (isActive && !isListening) {
      Breakpoints.getSmallMQL().addListener(this.breakpointListener);
      this.setState({ isListening: true }); // eslint-disable-line react/no-did-update-set-state
    }
    if (!isActive && isListening) {
      Breakpoints.getSmallMQL().removeListener(this.breakpointListener);
      this.setState({ isListening: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    const { isListening } = this.state;
    if (isListening) {
      Breakpoints.getSmallMQL().removeListener(this.breakpointListener);
    }
  }

  breakpointListener = () => {
    this.forceUpdate();
  }

  render() {
    const isSmallBreakpoint = Breakpoints.isS();
    const {
      activeOnHover,
      additionalClasses,
      children,
      isActive,
      useModal,
    } = this.props;

    const {
      border: borderColorOverride,
      container: additionalContainerClasses,
    } = additionalClasses;

    let borderColor = null;
    if (borderColorOverride) {
      borderColor = borderColorOverride;
    }

    const sharedClasses = [
      block,
      borderColor,
      additionalContainerClasses,
    ];

    if (isSmallBreakpoint) {
      return isActive && useModal
        ? (
          <Modal preventBackgroundScroll>
            <div
              className={classNames(
                sharedClasses,
                `${block}--modal`,
              )}
            >
              {children}
            </div>
          </Modal>
        )
        : null;
    }
    return (
      <div
        className={classNames(
          sharedClasses,
          `${block}--absolute`,
          {
            [styles.cardOverlayHover]: activeOnHover,
            active: isActive,
          },
        )}
        data-testid="cardOverlay"
      >
        {children}
      </div>
    );
  }
}
