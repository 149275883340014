import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import classnames from 'classnames';

import TeasePicture from 'components/TeasePicture';

import { content as contentPropType } from 'lib/CustomPropTypes';
import { getTeasePicture } from 'lib/teaseUtils';
import {
  getProductOfferPrices,
  isOnSale,
  getProductOffers,
  getProductOfferId,
} from 'lib/productOfferPriceHelpers';
import { Save } from 'components/SocialShareMenu/Save';
import { VerticalContext } from 'lib/ContextTypes';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

const TEASE_SIZES = {
  one: 'one',
  two: 'two',
};

const TEASE_SIZE_TO_IMAGE_SIZES = {
  [TEASE_SIZES.one]: {
    s: AIMS_FLAVORS.FOCAL_240x240,
  },
  [TEASE_SIZES.two]: {
    s: AIMS_FLAVORS.FOCAL_440x440,
  },
};

const TEASE_SIZE_TO_ASPECT_RATIO = {
  [TEASE_SIZES.one]: 'ASPECT_1X1',
  [TEASE_SIZES.two]: 'ASPECT_2X1',
};

/* eslint-disable max-len */
const FALLBACK_IMG_URL_MAP = {
  [TEASE_SIZES.two]: {
    better:
      'https://media4.s-nbcnews.com/i/newscms/2019_23/2888161/2x1_better_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    globalcitizen:
      'https://media1.s-nbcnews.com/i/newscms/2019_23/2888166/2x1_global-citizen_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    mach: 'https://media3.s-nbcnews.com/i/newscms/2019_23/2888181/2x1_mach_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    msnbc:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888186/2x1_msnbc_fallback_image_215abc36bbb66c1ffd105ebd4f190283.jpg',
    news: 'https://media1.s-nbcnews.com/i/newscms/2019_23/2888216/2x1_nbcnews_fallback_image_0_cbf07ab7dd92887d74bd5e4ed1bde2e5.jpg',
    select:
      'https://media4.s-nbcnews.com/i/newscms/2021_38/3507110/fallback_2_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media2.s-nbcnews.com/i/newscms/2019_23/2888206/2x1_think_fallback_image_0_a5e44c6d5a0a2527b7c92c966f3fb003.jpg',
    today:
      'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
  [TEASE_SIZES.one]: {
    better:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507103/fallback_1_1_better_b61f24cd574be68f4e1d2ac299748364.png',
    globalcitizen:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507104/fallback_1_1_globalcitizen_b61f24cd574be68f4e1d2ac299748364.png',
    msnbc:
      'https://media1.s-nbcnews.com/i/newscms/2021_38/3507106/fallback_1_1_msnbc_b61f24cd574be68f4e1d2ac299748364.png',
    news: 'https://media2.s-nbcnews.com/i/newscms/2021_38/3507107/fallback_1_1_nbc_b61f24cd574be68f4e1d2ac299748364.png',
    select:
      'https://media4.s-nbcnews.com/i/newscms/2021_38/3507108/fallback_1_1_select_b61f24cd574be68f4e1d2ac299748364.png',
    think:
      'https://media3.s-nbcnews.com/i/newscms/2021_38/3507109/fallback_1_1_think_b61f24cd574be68f4e1d2ac299748364.png',
    today:
      'https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2024-02/sunrise-846ed5.svg',
  },
};

export const ShopListByOne = (props) => {
  const [addToCartText, setAddToCartText] = useState('add to cart');

  const {
    card: {
      computedValues = {},
      id,
      isLazyLoaded = true,
      type,
      item: canonicalItem,
    } = {},
    card = {},
    size,
    width,
    totalNumberOfCards,
    indexOffset,
    onAddProduct,
    shouldShowDescription,
  } = props;

  const handleOnClick = () => {
    const { item = {} } = card;
    const { name = '', offers } = item;
    const [offer = {}] = offers ?? [];
    const priceMap = getProductOfferPrices(offer);
    const seller = offer?.seller?.name ?? null;
    const listPrice = priceMap?.list ?? null;
    const salePrice = priceMap?.sale ?? null;

    $t('track', 'mbt_bacon_click', {
      action: 'addToCart',
      item: {
        name,
        seller,
        listPrice,
        salePrice,
      },
    });
    setAddToCartText('added to cart');
    setTimeout(() => setAddToCartText('add to cart'), 600);
  };

  const productOffers = getProductOffers(card.item || []);
  const { headline: headlineText = '', dek } = computedValues;

  const vertical = useContext(VerticalContext);

  const sizes = TEASE_SIZE_TO_IMAGE_SIZES[size];

  const height = width / (size === 'two' ? 2 : 1);

  const computedTeasePicture = getTeasePicture(
    computedValues,
    FALLBACK_IMG_URL_MAP[size],
    vertical,
    TEASE_SIZE_TO_ASPECT_RATIO[size],
  );

  const [productOffer] = productOffers;
  const productPrice = getProductOfferPrices(productOffer);
  const productId = getProductOfferId(productOffer);

  const {
    description: {
      primary: description = '',
    } = {},
  } = canonicalItem || {};

  return (
    <div
      className={styles.baconCardsWidthByOne}
      data-contentid={id}
      data-testid="shop_list_by_one"
    >
      <div className={styles.imageAndSaveButtonWrapper}>
        <TeasePicture
          computedValues={computedTeasePicture}
          height={height}
          lazyload={isLazyLoaded}
          responsiveFlavors={sizes}
          type={type}
          width={width}
        />
        <Save
          contentId={card?.item?.id}
          contentType={card?.item?.type}
          options={{ isThumbnail: true, showCTA: true }}
        />
      </div>
      <p
        className={styles.productNumbering}
      >
        {`${indexOffset} of ${totalNumberOfCards}`}
      </p>
      <h3
        className={classnames({
          [styles.baconCardsWidthByOneHeader]: !shouldShowDescription,
          [styles.baconCardsWidthByOneHeaderWithDescription]: shouldShowDescription,
        })}
        data-testid="x-by-one__headline__header"
      >
        {headlineText}
      </h3>
      {shouldShowDescription && <p className={styles.description} data-testid="description">{dek || description}</p>}
      <div className={styles.productSellerWrapper}>
        {isOnSale({
          salePrice: productPrice.sale,
          listPrice: productPrice.list,
        }) ? (
            <div>
              <p className={styles.salePrice}>{`$${productPrice.sale}`}</p>
              <p className={styles.listPriceWithSale}>
                {`$${productPrice.list}`}
              </p>
            </div>
          ) : (
            <p className={styles.listPrice}>{`$${productPrice.list}`}</p>
          )}
        <button
          className={styles.addToCart}
          data-testid="shoplistbyone-add-to-cart"
          type="button"
          onClick={() => {
            handleOnClick();
            onAddProduct(productId);
          }}
        >
          <div alt="Add to Cart Icon" className={styles.addToCartIcon}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M7.39118 18.9723C8.28598 18.9723 9.01136 18.2469 9.01136 17.3521C9.01136 16.4573 8.28598 15.7319 7.39118 15.7319C6.49638 15.7319 5.771 16.4573 5.771 17.3521C5.771 18.2469 6.49638 18.9723 7.39118 18.9723Z"
                />
                <path
                  d="M17.2126 18.9723C18.1074 18.9723 18.8328 18.2469 18.8328 17.3521C18.8328 16.4573 18.1074 15.7319 17.2126 15.7319C16.3178 15.7319 15.5924 16.4573 15.5924 17.3521C15.5924 18.2469 16.3178 18.9723 17.2126 18.9723Z"
                />
                <path
                  d="M19 9.66659V3.88743C19 3.39469 18.6009 2.99559 18.1082 2.99559H5.04861C4.90889 2.99559 4.75003 2.99559 4.62379 2.99559L4.25041 1.31075C4.18055 1.00752 3.91002 0.791992 3.59862 0.791992H0.668914C0.299542 0.791992 3.05176e-05 1.0915 3.05176e-05 1.46088C3.05176e-05 1.83025 0.299542 2.12976 0.668914 2.12976H3.06649L5.87654 14.3057C5.9464 14.6089 6.21693 14.8244 6.52833 14.8244H18.1416C18.511 14.8244 18.8105 14.5249 18.8105 14.1555C18.8105 13.7862 18.511 13.4867 18.1416 13.4867H7.06047L6.67994 11.8382L18.1082 10.5584C18.6373 10.4826 19 10.1593 19 9.66659Z"
                />
              </g>
            </svg>
          </div>
          <span className={styles.addToCart}>{addToCartText}</span>
        </button>
      </div>
    </div>
  );
};

ShopListByOne.propTypes = {
  card: contentPropType,
  size: PropTypes.oneOf([TEASE_SIZES.one, TEASE_SIZES.two]),
  width: PropTypes.number.isRequired,
  indexOffset: PropTypes.number.isRequired,
  totalNumberOfCards: PropTypes.number.isRequired,
  onAddProduct: PropTypes.func.isRequired,
  shouldShowDescription: PropTypes.bool,
};

ShopListByOne.defaultProps = {
  card: {},
  size: TEASE_SIZES.two,
  shouldShowDescription: false,
};
