export const isLengthGreaterThan8 = (chars) => {
  if (!chars) {
    return null;
  }
  return chars.length >= 8;
};

export const getFontSizeFromCharLength = (chars) => {
  if (!chars || chars.length < 10) {
    return 'f3 f4-m';
  } if (chars.length <= 12) {
    return 'f2 f3-l';
  } if (chars.length <= 15) {
    return 'f1 f2-m f3-l';
  }
  return 'f1';
};
