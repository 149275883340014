import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const IsReadyContext = createContext();
IsReadyContext.displayName = 'IsReadyContext';

export function useIsReady() {
  const isReady = useContext(IsReadyContext);

  // double equal to check for undefined|null
  if (isReady == null) {
    throw new Error('useIsReady called without a IsReadyContext.Provider');
  }

  return isReady;
}

const SetIsReadyContext = createContext();
SetIsReadyContext.displayName = 'SetIsReadyContext';

export function useSetIsReady() {
  const setIsReady = useContext(SetIsReadyContext);

  if (!setIsReady) {
    throw new Error('useSetIsReady called without a SetIsReadyContext.Provider');
  }

  return setIsReady;
}

export function VideoIsReadyProvider({ children }) {
  const [isReady, setIsReady] = useState(false);

  return (
    <SetIsReadyContext.Provider value={setIsReady}>
      <IsReadyContext.Provider value={isReady}>
        {children}
      </IsReadyContext.Provider>
    </SetIsReadyContext.Provider>
  );
}

VideoIsReadyProvider.propTypes = {
  children: PropTypes.node,
};

VideoIsReadyProvider.defaultProps = {
  children: undefined,
};
