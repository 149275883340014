import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

/**
 *
 * @param {object} params
 * @param {string} params.slate
 * @param {boolean} params.displaySlate
 * @returns {JSX.Element|null}
 */
export function Slate({ slate, displaySlate }) {
  return displaySlate ? (
    <div
      className={`z-1 w-100 ${styles.coreVideoSlate} ${styles.coreVideoSlateImageContainer}`}
    >
      <img
        src={slate}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
        alt=""
      />
    </div>
  ) : null;
}

Slate.propTypes = {
  slate: PropTypes.string.isRequired,
  displaySlate: PropTypes.bool.isRequired,
};
