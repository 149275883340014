import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/IconLoader';

export function VideoLoadingAnimation({ isReady }) {
  const [showLoader, setShowLoader] = useState(true);
  return (
    <>
      {
        showLoader ? (
          <div
            className="video-loading-animation"
            style={{
              placeSelf: 'center',
            }}
          >
            <Loader
              showLoader={!isReady}
              animateIn
              animateOut
              onAnimationEnd={() => { setShowLoader(false); }}
            />
          </div>
        )
          : null
      }
    </>
  );
}

VideoLoadingAnimation.propTypes = {
  isReady: PropTypes.bool.isRequired,
};
