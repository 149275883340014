// eslint-disable-next-line object-curly-newline
import React, { useState, useRef, useCallback, useEffect } from 'react';
import BTE from 'lib/BTE';
import { StickyPIP } from 'components/StickyPIP';
import PropTypes from 'prop-types';
import { useIsVideoPaused } from 'components/VideoContainer/context/state/isPaused';
import { useVideoVolume } from 'components/VideoContainer/context/state/volume';
import { VIDEO_RESERVE_PIP_GLOBAL_EVENT, VIDEO_PLAYING_EVENT_ATTENUATION } from 'lib/videoUtils';
import { useVideoController } from '../context/videoController';

export function StickyPipControl({
  children,
  className,
  placeholderClassName,
  shouldPauseWhenMutedAndOutOfView,
  shouldStickOnlyOnFirstView,
  disableStickyOnPause,
  disableStickyOnMute,
  onStuckChange,
  pipAlignDesktop,
  pipAlignMobile,
}) {
  const [isStickDisabled, setIsStickDisabled] = useState(false);
  const isPaused = useIsVideoPaused();
  const { play, pause } = useVideoController();
  const { muted: isMuted } = useVideoVolume();
  const frameRef = useRef({});

  useEffect(() => {
    const onReservePip = ({ ref }) => setIsStickDisabled(ref !== frameRef.current);

    const args = [VIDEO_RESERVE_PIP_GLOBAL_EVENT, onReservePip, VIDEO_PLAYING_EVENT_ATTENUATION];

    BTE.on(...args);

    return () => BTE.remove(...args);
  }, []);

  const handleOnStuckChange = useCallback(
    (isStuck) => {
      onStuckChange(isStuck);
      if (isStuck) {
        BTE.trigger(
          VIDEO_RESERVE_PIP_GLOBAL_EVENT,
          { ref: frameRef.current },
          VIDEO_PLAYING_EVENT_ATTENUATION,
        );
      }
    },
    [onStuckChange],
  );

  const handleOnStickyClose = useCallback(
    (isClosed) => {
      if (isClosed && !isPaused) {
        pause();
      }
    },
    [isPaused, pause],
  );

  const shouldStickOnViewportExit = useCallback(() => {
    // only look at the value of `isMuted` if `disableStickyOnMute` is true,
    // but then we want `enabled` only if NOT `isMuted`
    const isStickyEnabledBasedOnMuteState = !(disableStickyOnMute && isMuted);

    // only look at the value of `playing` if `disableStickyOnPause` is true
    // otherwise, always enabled if `disableStickyOnPause` is false
    const isStickyEnabledBasedOnPlayingState = disableStickyOnPause ? !isPaused : true;

    return isStickyEnabledBasedOnMuteState && isStickyEnabledBasedOnPlayingState;
  }, [disableStickyOnMute, disableStickyOnPause, isMuted, isPaused]);

  const handleOnInViewChange = useCallback(
    (isInView) => {
      if (!isPaused) {
        setIsStickDisabled(false);

        if (shouldPauseWhenMutedAndOutOfView && !isInView && isMuted) {
          pause();
        }
      } else if (isInView && isMuted) {
        setIsStickDisabled(false);

        if (!isPaused) play();
      }
    },
    [isMuted, isPaused, pause, play, shouldPauseWhenMutedAndOutOfView],
  );

  return (
    <StickyPIP
      className={className}
      getShouldStickOnViewportExit={shouldStickOnViewportExit}
      onClose={handleOnStickyClose}
      onStuckChange={handleOnStuckChange}
      enabled={!isStickDisabled}
      onInViewChange={handleOnInViewChange}
      placeholderClassName={placeholderClassName}
      shouldStickOnlyOnFirstView={shouldStickOnlyOnFirstView}
      pipAlignDesktop={pipAlignDesktop}
      pipAlignMobile={pipAlignMobile}
    >
      {children}
    </StickyPIP>
  );
}

StickyPipControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
  shouldPauseWhenMutedAndOutOfView: PropTypes.bool,
  shouldStickOnlyOnFirstView: PropTypes.bool,
  disableStickyOnMute: PropTypes.bool,
  disableStickyOnPause: PropTypes.bool,
  pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
  pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),
  onStuckChange: PropTypes.func,
};

StickyPipControl.defaultProps = {
  className: undefined,
  placeholderClassName: undefined,
  shouldPauseWhenMutedAndOutOfView: false,
  shouldStickOnlyOnFirstView: false,
  disableStickyOnMute: false,
  disableStickyOnPause: true,
  pipAlignDesktop: 'bottom',
  pipAlignMobile: 'bottom',
  onStuckChange: () => {},
};
