import { getFeatureConfigForBrand } from './getFeatureStatus';
import { AUTOPLAY_ARTICLE_HERO_VIDEOS } from './brandFeatures';

/**
 * Returns config object to set auto play rules for an article's main video art. Also known as "hero".
 * @param {PrimaryMedia} primaryMedia The article's primaryMedia also known as Main Art.
 * @param {Boolean} isChromeless Determines if article is in the chromeless context. Aka native app.
 * @param {VerticalType} vertical Sets the brand that published the article.
 */

export function getMainArtAutoPlayValues(primaryMedia, isChromeless, vertical) {
  const autoPlayConfig = getFeatureConfigForBrand(AUTOPLAY_ARTICLE_HERO_VIDEOS, vertical);

  const {
    muted: onlyAutoplayMuted = false,
    chromelessAutoplay = false,
  } = autoPlayConfig || {};

  let autoPlay = typeof primaryMedia?.autoplay === 'boolean'
    ? primaryMedia.autoplay
    : autoPlayConfig?.autoplay || false;

  autoPlay = isChromeless ? chromelessAutoplay : autoPlay;
  return {
    autoPlay,
    onlyAutoplayMuted,
  };
}
