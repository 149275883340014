import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const LiveLabel = () => {
  const { i18n } = useTranslation();

  return (i18n.language === 'es')
    ? <span className={styles.liveLabel} data-testid="spanishLiveLabel" style={{ fontSize: '9px', fontFamily: 'sans-serif' }}>EN&nbsp;VIVO</span>
    : (
      <svg data-testid="liveLabel" width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="8" r="4" fill="white" />
        <path d="M15.0781 13V4.43359H16.8945V11.5H20.3691V13H15.0781ZM21.8633 13V4.43359H23.6797V13H21.8633ZM30.7227 4.43359H32.5566L29.6445 13H27.6641L24.7578 4.43359H26.5918L28.2031 9.53125C28.293 9.83203 28.3848 10.1836 28.4785 10.5859C28.5762 10.9844 28.6367 11.2617 28.6602 11.418C28.7031 11.0586 28.8496 10.4297 29.0996 9.53125L30.7227 4.43359ZM38.5742 13H33.6406V4.43359H38.5742V5.92188H35.457V7.80273H38.3574V9.29102H35.457V11.5H38.5742V13Z" fill="white" />
      </svg>
    );
};
