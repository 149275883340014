import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatAsISO } from 'lib/DateTime';
import Timestamp from '../Timestamp';

function ContentTimestamp(props) {
  const {
    dateCreated, // publish
    datePublished, // update, this is expected
    dateOnly,
    dateLabel,
    delimiter,
    layout,
    meta,
    showCreatedDate,
    styles,
    timeZone,
    hideMicroData,
    testId,
  } = props;

  if (datePublished === '') {
    return null;
  }

  const { t } = useTranslation();

  const contentHasBeenUpdated = dateCreated !== datePublished;
  const lastUpdatedDateString = contentHasBeenUpdated ? datePublished : dateCreated;

  const showUpdatedTimestamp = (!showCreatedDate || contentHasBeenUpdated);
  const shouldRenderLastPublishedMetaTag = (meta && contentHasBeenUpdated && !hideMicroData);
  const shouldRenderCreatedMetaTag = (meta && showCreatedDate && !hideMicroData);

  const dateCreatedISOString = formatAsISO(dateCreated); // Only needed for meta tag
  const lastUpdatedISODateString = formatAsISO(datePublished);

  return (
    <>
      <time
        className={classNames('relative z-1', styles)}
        dateTime={lastUpdatedDateString}
        data-testid={testId || 'timestamp__datePublished'}
        {...(hideMicroData ? {} : { itemProp: 'datePublished' })}
        content={lastUpdatedISODateString}
      >
        {showCreatedDate && (
          <Timestamp
            dateOnly={dateOnly}
            dateString={dateCreated}
            delimiter={delimiter}
            layout={layout}
            timeZone={timeZone}
          />
        )}

        {showUpdatedTimestamp
          && (
            <>
              {showCreatedDate && <span>&nbsp;/&nbsp;</span>}
              {dateLabel && (
                <>
                  {t(dateLabel)}
                  &nbsp;
                </>
              )}
              <Timestamp
                dateOnly={dateOnly}
                dateString={lastUpdatedDateString}
                delimiter={delimiter}
                layout={layout}
              />
            </>
          )}
      </time>

      {shouldRenderCreatedMetaTag && (
        <meta
          data-test="timestamp__datePublished--meta"
          data-testid="timestamp__datePublished--meta"
          itemProp="datePublished"
          content={dateCreatedISOString}
        />
      )}

      {shouldRenderLastPublishedMetaTag && (
        <meta
          data-test="timestamp__dateModified--meta"
          data-testid="timestamp__dateModified--meta"
          itemProp="dateModified"
          content={lastUpdatedISODateString}
        />
      )}
    </>
  );
}

ContentTimestamp.propTypes = {
  dateCreated: PropTypes.string,
  dateOnly: PropTypes.bool,
  datePublished: PropTypes.string,
  dateLabel: PropTypes.string,
  delimiter: PropTypes.string,
  layout: PropTypes.string,
  meta: PropTypes.bool,
  showCreatedDate: PropTypes.bool,
  hideMicroData: PropTypes.bool,
  styles: PropTypes.string,
  timeZone: PropTypes.string,
  testId: PropTypes.string,
};

ContentTimestamp.defaultProps = {
  dateCreated: '',
  dateOnly: false,
  datePublished: '',
  dateLabel: 'Updated',
  delimiter: '',
  layout: '',
  meta: false,
  showCreatedDate: false,
  hideMicroData: false,
  styles: '',
  timeZone: '',
  testId: null,
};

export default ContentTimestamp;
