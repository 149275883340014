import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

/**
 * exported for testing
 * @package
 */
export const IsAdPlayingContext = createContext();
IsAdPlayingContext.displayName = 'IsAdPlayingContext';

export function useIsAdPlaying() {
  const isAdPlaying = useContext(IsAdPlayingContext);

  // double equal to check for undefined|null
  if (isAdPlaying == null) {
    throw new Error('useIsAdPlaying called without a IsAdPlayingContext.Provider');
  }

  return isAdPlaying;
}

const SetIsAdPlayingContext = createContext();
SetIsAdPlayingContext.displayName = 'SetIsAdPlayingContext';

export function useSetIsAdPlaying() {
  const setIsAdPlaying = useContext(SetIsAdPlayingContext);

  if (!setIsAdPlaying) {
    throw new Error('useSetIsAdPlaying called without a SetIsAdPlayingContext.Provider');
  }

  return setIsAdPlaying;
}

export function VideoIsAdPlayingProvider({ children }) {
  const [isAdPlaying, setIsAdPlaying] = useState(false);

  return (
    <SetIsAdPlayingContext.Provider value={setIsAdPlaying}>
      <IsAdPlayingContext.Provider value={isAdPlaying}>
        {children}
      </IsAdPlayingContext.Provider>
    </SetIsAdPlayingContext.Provider>
  );
}

VideoIsAdPlayingProvider.propTypes = {
  children: PropTypes.node,
};

VideoIsAdPlayingProvider.defaultProps = {
  children: undefined,
};
