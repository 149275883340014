/**
 *
 * @param {string} type
 * @returns {string}
 */
export const getIconForArticleType = (type) => {
  switch (type) {
    case 'related-article':
      return 'icon-dot';
    case 'slideshow':
      return 'icon-gallery';
    case 'video':
      return 'icon-video';
    case 'shop':
      return 'icon-shop';
    default:
      return '';
  }
};
