import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

export function NotchedHorizontalRule({ className, ...restProps }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classnames(styles.notchedHorizontalRule, className)} {...restProps} />
  );
}

NotchedHorizontalRule.propTypes = {
  className: PropTypes.string,
};

NotchedHorizontalRule.defaultProps = {
  className: null,
};
