import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { responsiveFlavors as ResponsiveFlavorsPropType } from 'lib/CustomPropTypes';

import NativeLazyLoad from 'components/NativeLazyLoad';
import ImageCredit from 'components/ImageCredit';
import { Picture } from 'components/Picture';
import { Placeholder } from 'components/Picture/Placeholder';

import styles from './styles.module.scss';

/**
 * If lazyloading is set, returns element wrapped with NativeLazyLoad component
 * otherwise only child element is returned
 * @param {element|object} children
 * @param {bool} lazyloaded whether the child elements should be lazy loaded
 * @param {*} props data that is passed down in the optional NativeLazyLoad wrapper
 * @returns {React.ReactElement}
 */
function Wrapper({ children, lazyloaded, ...props }) {
  if (lazyloaded) {
    return <NativeLazyLoad {...props}>{children}</NativeLazyLoad>;
  }
  return children;
}

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  lazyloaded: PropTypes.bool,
};

Wrapper.defaultProps = {
  children: {},
  lazyloaded: true,
};

const TeasePicture = ({
  responsiveFlavors,
  className,
  lazyload,
  previewRedirect,
  type,
  height,
  width,
  hideCredit,
  ...remainingProps
}, context = {}) => {
  if (!responsiveFlavors) return null;

  const {
    computedValues,
    placeholder,
    placeholderColor,
  } = remainingProps;

  const {
    teaseImage,
  } = computedValues || {};

  if (!teaseImage && placeholder) {
    return (
      <Placeholder
        responsiveFlavors={responsiveFlavors}
        color={placeholderColor}
      />
    );
  }

  let pictureHeight;
  let pictureWidth;
  if (height && width) {
    pictureHeight = height;
    pictureWidth = width;
  } else if (teaseImage?.height && teaseImage?.width) {
    pictureHeight = teaseImage.height;
    pictureWidth = teaseImage.width;
  }

  const { topOfPagePackages } = context;
  const lazyloaded = lazyload && !topOfPagePackages;
  return (
    <>
      <Wrapper
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        lazyloaded={lazyloaded}
      >
        <Picture
          className={classNames(
            'teasePicture',
            styles.container,
            { [className]: className },
          )}
          lazyloaded={lazyloaded}
          responsiveFlavors={responsiveFlavors}
          originalHeight={pictureHeight}
          originalWidth={pictureWidth}
          {...remainingProps}
        />
      </Wrapper>
      {!hideCredit && (
        <ImageCredit
          type={type}
          responsiveFlavors={responsiveFlavors}
          {...remainingProps}
        />
      )}
    </>
  );
};

TeasePicture.contextTypes = {
  topOfPagePackages: PropTypes.bool,
};

TeasePicture.propTypes = {
  responsiveFlavors: ResponsiveFlavorsPropType,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  lazyload: PropTypes.bool,
  placeholder: PropTypes.bool,
  previewRedirect: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  hideCredit: PropTypes.bool,
};

TeasePicture.defaultProps = {
  responsiveFlavors: {},
  className: null,
  type: null,
  lazyload: true,
  placeholder: false,
  previewRedirect: true,
  height: null,
  width: null,
  hideCredit: false,
};

export default TeasePicture;
