import React from 'react';
import PropTypes from 'prop-types';

export function SpeakerMuted({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="speaker-muted"
    >
      <path
        d="M11.65 4.28V19.72C11.65 20 11.48 20.08 11.27 19.89L5.86 15.09H2.9C2.62 15.09 2.38 14.86 2.38 14.57V9.43C2.38 9.15 2.61 8.91 2.9 8.91H5.86L11.27 4.11C11.48 3.92 11.65 3.99 11.65 4.28Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1949 12.5238L14.0231 14.6956L15.471 16.1435L17.6428 13.9717L19.8147 16.1435L21.2625 14.6956L19.0907 12.5238L21.2625 10.352L19.8146 8.9041L17.6428 11.0759L15.471 8.9041L14.0231 10.352L16.1949 12.5238Z"
        fill="currentColor"
      />
    </svg>
  );
}

SpeakerMuted.propTypes = {
  className: PropTypes.string,
};

SpeakerMuted.defaultProps = {
  className: undefined,
};
