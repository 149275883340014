import React from 'react';
import { useIsVideoPaused } from 'components/VideoContainer/context/state/isPaused';
import { useVideoController } from 'components/VideoContainer/context/videoController';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export const PlayPauseButton = ({ mobile }) => {
  const videoController = useVideoController();
  const isPaused = useIsVideoPaused();

  const clickFunction = isPaused ? videoController.play : videoController.pause;
  const playPauseIcon = isPaused ? 'play' : 'pause';
  const iconClasses = `icon icon-${playPauseIcon}`;

  return (
    <button type="button" onClick={() => clickFunction()} className={`play-pause-button ${mobile ? styles.playPauseMobile : styles.playPauseDesktop}`}>
      <span className={iconClasses} data-testid={`video-${playPauseIcon}-button`} />
    </button>
  );
};

PlayPauseButton.propTypes = {
  mobile: PropTypes.bool,
};

PlayPauseButton.defaultProps = {
  mobile: false,
};
