export function addProductToCart(productIds, { trackingId }) {
  if (window.NFW) {
    window.NFW.addProducts(productIds, { trackingId });
  } else {
    window.open(
      'https://content-cart-api-prod.northfork.se/carts/push-to-cart'
      + `?campaign=${trackingId}&storeId=2503&storeProvider=target&partner=nbcu&extraProductIds=${productIds.join(',')}`,
      '_blank',
    );
  }
}
