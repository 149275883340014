import React, { useCallback } from 'react';
import classNames from 'classnames';

import { useVideoController } from 'components/VideoContainer/context/videoController';
import { useVideoVolume } from 'components/VideoContainer/context/state/volume';

import { dBFSToGain, gainToDBFS } from 'lib/videoUtils';
import { VerticalContext } from 'lib/ContextTypes';

import { SpeakerMuted } from './icons/SpeakerMuted';
import { SpeakerUnmuted } from './icons/SpeakerUnmuted';

import styles from './styles.module.scss';

const loudnessMinimum = -50;

export function Volume() {
  const { volume, muted } = useVideoVolume();
  const videoController = useVideoController();
  const vertical = React.useContext(VerticalContext);

  const onVolumeChangeListener = useCallback((e) => {
    const dBFS = e.target.valueAsNumber;
    const gain = dBFS === loudnessMinimum
      ? 0
      : dBFSToGain(dBFS);

    if (gain === 0) {
      videoController.setMute(true);
      return;
    }

    if (muted && gain > 0) {
      videoController.setMute(false);
    }

    videoController.setVolume(gain);
  }, [videoController, muted]);

  const volumeDBFS = muted
    ? loudnessMinimum
    : Math.max(gainToDBFS(volume), loudnessMinimum);
  const volumePercentageFromDBFS = muted
    ? 0
    : Math.round((1 - Math.abs(volumeDBFS / loudnessMinimum)) * 100);

  const speakerIcon = muted || volume === 0
    ? <SpeakerMuted />
    : (
      <SpeakerUnmuted
        className={classNames({
          [styles.speakerQuiet]: volumePercentageFromDBFS < 50,
        })}
      />
    );

  return (
    <div className={classNames(
      styles.volumeContainer,
      {
        [styles.volumeContainerOnNoticias]: vertical === 'noticias',
      },
    )}
    >
      <button
        type="button"
        onClick={() => videoController.setMute(!muted)}
        className={styles.muteToggle}
        data-testid="mute-toggle"
      >
        {speakerIcon}
      </button>
      <div className={styles.volumeSliderContainer}>
        <input
          type="range"
          className={styles.volumeSlider}
          style={{
            '--volume': `${volumePercentageFromDBFS}%`,
          }}
          value={volumeDBFS}
          onChange={onVolumeChangeListener}
          min={loudnessMinimum}
          max="0"
          step="0.5"
          data-testid="volume-slider"
        />
      </div>
    </div>
  );
}
