import React from 'react';
import PropTypes from 'prop-types';
import { content as contentPropType } from 'lib/CustomPropTypes';
import { WidthByOne } from '../WidthByOne/WidthByOne';

export const TwoByOne = ({
  card,
  openInNewTab,
  pkgType,
  showEyebrow,
  showTimestamp,
  width,
  trackBacon,
  title,
  variant,
}) => (
  <WidthByOne
    card={card}
    openInNewTab={openInNewTab}
    pkgType={pkgType}
    showEyebrow={showEyebrow}
    showTimestamp={showTimestamp}
    size="two"
    width={width}
    title={title}
    trackBacon={trackBacon}
    variant={variant}
  />
);

TwoByOne.propTypes = {
  title: PropTypes.string,
  trackBacon: PropTypes.func,
  card: contentPropType,
  openInNewTab: PropTypes.bool,
  pkgType: PropTypes.string,
  showEyebrow: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.number,
};

TwoByOne.defaultProps = {
  title: '',
  trackBacon: () => {},
  card: {},
  openInNewTab: false,
  pkgType: null,
  showEyebrow: true,
  showTimestamp: false,
  variant: 'small',
  width: 260,
};
