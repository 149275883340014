import React from 'react';
import classNames from 'classnames';
import Proptypes from 'prop-types';

const ShoppingCartIcon = ({ additionalClasses }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" className={classNames(additionalClasses, 'shoppable-icon')} xmlns="http://www.w3.org/2000/svg">
    <title id="shoppingCartIcon">The ingredients to this recipe can be purchased online</title>
    <rect width="36" height="36" fill="#FF503C" />
    <g clipPath="url(#clip0_1325_36556)">
      <path d="M15.7802 27.9705C16.7221 27.9705 17.4856 27.2069 17.4856 26.265C17.4856 25.3231 16.7221 24.5596 15.7802 24.5596C14.8383 24.5596 14.0747 25.3231 14.0747 26.265C14.0747 27.2069 14.8383 27.9705 15.7802 27.9705Z" fill="white" />
      <path d="M26.1185 27.9705C27.0604 27.9705 27.824 27.2069 27.824 26.265C27.824 25.3231 27.0604 24.5596 26.1185 24.5596C25.1766 24.5596 24.4131 25.3231 24.4131 26.265C24.4131 27.2069 25.1766 27.9705 26.1185 27.9705Z" fill="white" />
      <path d="M28 18.1752V12.0919C28 11.5732 27.5799 11.1531 27.0612 11.1531H13.3143C13.1672 11.1531 13 11.1531 12.8671 11.1531L12.4741 9.37956C12.4005 9.06037 12.1158 8.8335 11.788 8.8335H8.70409C8.31527 8.8335 8 9.14877 8 9.53758C8 9.9264 8.31527 10.2417 8.70409 10.2417H11.2279L14.1858 23.0584C14.2593 23.3776 14.5441 23.6045 14.8719 23.6045H27.0964C27.4852 23.6045 27.8005 23.2892 27.8005 22.9004C27.8005 22.5116 27.4852 22.1963 27.0964 22.1963H15.432L15.0315 20.4611L27.0612 19.114C27.6182 19.0342 28 18.6939 28 18.1752Z" fill="white" />
    </g>
  </svg>
);

ShoppingCartIcon.propTypes = {
  additionalClasses: Proptypes.string,
};

ShoppingCartIcon.defaultProps = {
  additionalClasses: '',
};

export {
  ShoppingCartIcon,
};
