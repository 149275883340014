import React from 'react';
import PropTypes from 'prop-types';
import './styles.themed.scss';

export default function InfoTile({ value, label }) {
  if (!value) {
    return null;
  }

  return (
    <div className="info-tile" data-testid="info-tile">
      <div className="info-tile__key black-print">{label}</div>
      <div className="info-tile__value black-print">{value}</div>
    </div>
  );
}

InfoTile.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

InfoTile.defaultProps = {
  value: '',
  label: '',
};
