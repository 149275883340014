import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  getFormattedDateString,
  getFormattedDateTimeString,
  getFormattedDateYearString,
  getFormattedMonthAndDayString,
} from 'lib/DateTime';

function Timestamp(props) {
  const {
    dateOnly,
    dateString,
    delimiter,
    layout,
    timeZone,
  } = props;

  if (dateString === '') {
    return null;
  }

  const formattedDateString = getFormattedDateString(dateString, timeZone, {}, i18next);
  const formattedDateTime = getFormattedDateTimeString(dateString, timeZone, delimiter, i18next);
  const formattedMonthAndDay = getFormattedMonthAndDayString(dateString, timeZone, i18next);
  const formattedYear = getFormattedDateYearString(dateString, timeZone);

  let timestampToRender;
  if (dateOnly) {
    timestampToRender = formattedDateString;
  } else {
    timestampToRender = formattedDateTime;
  }

  return (
    <>
      {layout === 'split' ? (
        <>
          <span className="dib mr1 ws-nowrap" data-test="timestamp__monthDay" data-testid="timestamp__monthDay">
            {formattedMonthAndDay}
            {delimiter}
          </span>
          <span className="dib ws-nowrap" data-test="timestamp__year">
            {formattedYear}
          </span>
        </>
      ) : (
        timestampToRender
      )}
    </>
  );
}

Timestamp.propTypes = {
  dateOnly: PropTypes.bool,
  dateString: PropTypes.string,
  delimiter: PropTypes.string,
  layout: PropTypes.string,
  timeZone: PropTypes.string,
};

Timestamp.defaultProps = {
  dateOnly: false,
  dateString: '',
  delimiter: ' / ',
  layout: undefined,
  timeZone: undefined,
};

export default Timestamp;
