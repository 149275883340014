import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoTile from 'components/Recipe/InfoTile';
import { getShortDurationString } from 'lib/DateTime';
import styles from './styles.module.scss';

const RecipeDetails = ({
  cookTime, prepTime, servingSize, yields, type,
}) => {
  if (type !== 'recipe' && type !== 'relatedRecipe') return null;

  const cookTimeStr = getShortDurationString(cookTime);
  const prepTimeStr = getShortDurationString(prepTime);
  const servingsLabel = servingSize ? 'Servings:' : 'Yields:';
  const servingsValue = servingSize || yields;

  return (
    <div className={classNames(styles.container, 'recipe-detail-container')} data-testid="recipe-details">
      <InfoTile value={cookTimeStr} label="Cook Time:" />
      <InfoTile value={prepTimeStr} label="Prep Time:" />
      {servingsValue?.length ? <InfoTile value={servingsValue} label={servingsLabel} /> : null }
    </div>
  );
};

RecipeDetails.propTypes = {
  cookTime: PropTypes.string.isRequired,
  prepTime: PropTypes.string.isRequired,
  servingSize: PropTypes.string,
  yields: PropTypes.string,
  type: PropTypes.string.isRequired,
};

RecipeDetails.defaultProps = {
  servingSize: '',
  yields: '',
};

export default RecipeDetails;
