import React from 'react';

import { useVideoVolume } from 'components/VideoContainer/context/state/volume';
import { VideoUnmuteButton } from 'components/VideoUnmuteButton';
import { useVideoController } from 'components/VideoContainer/context/videoController';
import { useIsReady } from 'components/VideoContainer/context/state/isReady';

export function TapToUnmuteButton() {
  const videoVolume = useVideoVolume();
  const videoController = useVideoController();
  const isReady = useIsReady();

  return (
    isReady && videoVolume.muted ? (
      <VideoUnmuteButton
        isLive
        show
        onClickUnmute={() => videoController.setMute(false)}
      />
    ) : null
  );
}

