import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

const VideoTime = createContext();
VideoTime.displayName = 'VideoTime';

export function useVideoTime() {
  const videoTime = useContext(VideoTime);

  if (!videoTime) {
    throw new Error('useVideoTime called without a VideoTime.Provider');
  }

  return videoTime;
}

const SetVideoTime = createContext();
SetVideoTime.displayName = 'SetVideoTime';

export function useSetVideoTime() {
  const setVideoTime = useContext(SetVideoTime);

  if (!setVideoTime) {
    throw new Error('useSetVideoTime called without a SetVideoTime.Provider');
  }

  return setVideoTime;
}

export function VideoTimeProvider({ children }) {
  const [videoTime, setVideoTime] = useState({
    currentTime: 0,
    duration: 0,
  });

  return (
    <SetVideoTime.Provider value={setVideoTime}>
      <VideoTime.Provider value={videoTime}>
        {children}
      </VideoTime.Provider>
    </SetVideoTime.Provider>
  );
}

VideoTimeProvider.propTypes = {
  children: PropTypes.node,
};

VideoTimeProvider.defaultProps = {
  children: undefined,
};
