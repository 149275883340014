import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ArticleDek = ({
  dek, hasNoImage, isShoppingSection, isLiveBreakingNews,
}) => (
  <div
    className={classNames(
      styles.articleDek,
      {
        [styles.withImage]: !hasNoImage,
        [styles.isShoppingSection]: isShoppingSection,
        [styles.isLiveBreakingNews]: isLiveBreakingNews,
      },
    )}
    data-testid="article-dek"
  >
    {dek}
  </div>
);

ArticleDek.propTypes = {
  dek: PropTypes.string.isRequired,
  hasNoImage: PropTypes.bool,
  isShoppingSection: PropTypes.bool,
  isLiveBreakingNews: PropTypes.bool,
};

ArticleDek.defaultProps = {
  hasNoImage: false,
  isShoppingSection: false,
  isLiveBreakingNews: false,
};

export { ArticleDek };
