import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { layoutContext as LayoutContextPropType, packageContext as packageContextPropType } from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  SEE_MORE_BUTTON,
} from 'lib/brandFeatures';

import Link from 'components/Link';
import TitleLogo from 'components/TitleLogo';

import styles from './styles.module.scss';
import './styles.themed.scss';

const titleColorArray = ['blue', 'green', 'yellow'];

const PackageTitle = ({
  metadata,
  alternateDisplayFormat,
  titleBgColor,
  size,
  className,
  HTag,
}, context) => {
  if (!(metadata && (metadata.title || metadata.logoUrl))) {
    return null;
  }

  const {
    title, logoUrl, seeAllText, seeAllUrl, description, icid, anchorId,
  } = metadata;
  const { layoutType, vertical, pkgTitleColorIndex } = context;

  const isRailLayout = layoutType === 'leftRail' || layoutType === 'rightRail';

  const isFullWidth = layoutType === 'fullWidth';

  const shouldRenderButton = getFeatureConfigForBrand(
    SEE_MORE_BUTTON,
    vertical,
  );
  const isNotAlternateDisplay = !alternateDisplayFormat;

  let titleColor = false;

  if (titleBgColor) {
    titleColor = titleBgColor;
  } else if (typeof pkgTitleColorIndex === 'number') {
    titleColor = titleColorArray[pkgTitleColorIndex];
  }

  const headingClasses = classNames(
    styles.titleText,
    'package-title__title-text',
    {
      [`package-title__title-text--${titleColor}`]: titleColor,
      [`package-title__title-text--${size}`]: size,
    },
  );

  const containerClasses = classNames(
    'package-title__container',
    'package-title',
    styles.packageTitle,
    {
      [styles.rail]: isRailLayout,
      [styles.fullWidth]: isFullWidth,
      'package-title--with-description': description,
      [styles.alternateDisplayFormat]: alternateDisplayFormat,
      'package-title--alternate-display-format': alternateDisplayFormat,
      [className]: className,
    },
  );

  const link = seeAllUrl && !shouldRenderButton
    ? (
      <Link
        className={classNames(
          'package-title__see-all-link',
          {
            'package-title__see-all-link--alt-color': shouldRenderButton && isNotAlternateDisplay,
          },
        )}
        to={seeAllUrl}
        data-test="package-title-see-all"
        icid={icid}
      >
        {seeAllText || i18next.t('See More')}
      </Link>
    )
    : null;

  return (
    <div className={containerClasses} data-test="package-title">
      <HTag className={headingClasses} data-test="package-title-title" id={anchorId}>
        <TitleLogo logoUrl={logoUrl} title={title} />
      </HTag>
      {link}
      {description && (
        <p className="package-title__description">
          {description}
        </p>
      )}
    </div>
  );
};

PackageTitle.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.any),
  alternateDisplayFormat: PropTypes.bool,
  titleBgColor: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  HTag: PropTypes.string,
};

PackageTitle.defaultProps = {
  metadata: {},
  alternateDisplayFormat: false,
  titleBgColor: null,
  size: null,
  className: null,
  HTag: 'h2',
};

PackageTitle.contextTypes = {
  ...LayoutContextPropType,
  ...packageContextPropType,
};

export default PackageTitle;
