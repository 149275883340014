import React from 'react';
import PropTypes from 'prop-types';
import { isOnSale } from 'lib/productOfferPriceHelpers';

/**
 *
 * @param {string} props.listPrice - list price in the form '#.##'
 * @param {string} props.salePrice - only present if item is on sale, also arrives as '#.##'
 * @param {string} props.seller - name of the retailer selling the product in questi
 * @returns {React.ReactElement}
 */
function ShopButtonVariation({
  listPrice,
  salePrice,
  seller,
}) {
  let priceText;
  const renderSale = isOnSale({ salePrice, listPrice });

  if (renderSale) {
    priceText = (
      <>
        <span
          className="salePrice"
          data-test="salePrice-test"
          data-testid="salePrice-test"
        >
          {`$${salePrice} `}
        </span>
        <span
          className="listPrice onSale"
          data-test="listPrice-test"
          data-testid="listPrice-test"
        >
          {`$${listPrice}`}
        </span>
      </>
    );
  } else {
    priceText = (
      <span
        className="listPrice"
        data-test="listPrice-test"
        data-testid="listPrice-test"
      >
        {`$${listPrice}`}
      </span>
    );
  }

  return (
    <span className="shop-button-info__vendor-name">
      {priceText}
      <span
        className="sellerText"
        data-test="sellerText-test"
        data-testid="sellerText-test"
      >
        {` AT ${seller}`}
      </span>
    </span>
  );
}

ShopButtonVariation.propTypes = {
  listPrice: PropTypes.string,
  seller: PropTypes.string,
  salePrice: PropTypes.string,
};

ShopButtonVariation.defaultProps = {
  listPrice: null,
  salePrice: null,
  seller: null,
};

export { ShopButtonVariation };
