import React from 'react';
import PropTypes from 'prop-types';

export function SpeakerUnmuted({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.65 4.27999V19.72C11.65 20 11.48 20.08 11.27 19.89L5.86 15.09H2.9C2.62 15.09 2.38 14.86 2.38 14.57V9.42999C2.38 9.14999 2.61 8.90999 2.9 8.90999H5.86L11.27 4.10999C11.48 3.91999 11.65 3.98999 11.65 4.27999Z"
        fill="currentColor"
      />
      <path
        d="M13.62 16V14C14.73 14 15.62 13.11 15.62 12C15.62 10.89 14.73 10 13.62 10V8C15.83 8 17.62 9.79 17.62 12C17.62 14.21 15.83 16 13.62 16Z"
        fill="currentColor"
      />
      <path
        d="M21.62 12C21.62 7.58 18.04 4 13.62 4V6C16.93 6 19.62 8.69 19.62 12C19.62 15.31 16.93 18 13.62 18V20C18.04 19.99 21.61 16.41 21.62 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

SpeakerUnmuted.propTypes = {
  className: PropTypes.string,
};

SpeakerUnmuted.defaultProps = {
  className: undefined,
};
