import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';

const SubtitleTrackContext = createContext();
SubtitleTrackContext.displayName = 'SubtitleTrackContext';

export function useSubtitleTrack() {
  const subtitleTrack = useContext(SubtitleTrackContext);

  return subtitleTrack;
}

const SetSubtitleTrackContext = createContext();
SetSubtitleTrackContext.displayName = 'SetSubtitleTrackContext';

export function useSetSubtitleTrack() {
  const setSubtitleTrack = useContext(SetSubtitleTrackContext);

  if (!setSubtitleTrack) {
    throw new Error('useSetSubtitleTrack called without a SetSubtitleTrackContext.Provider');
  }

  return setSubtitleTrack;
}

export function VideoSubtitleTrackProvider({ children }) {
  const [subtitleTrack, setSubtitleTrack] = useState(false);

  return (
    <SetSubtitleTrackContext.Provider value={setSubtitleTrack}>
      <SubtitleTrackContext.Provider value={subtitleTrack}>
        {children}
      </SubtitleTrackContext.Provider>
    </SetSubtitleTrackContext.Provider>
  );
}

VideoSubtitleTrackProvider.propTypes = {
  children: PropTypes.node,
};

VideoSubtitleTrackProvider.defaultProps = {
  children: undefined,
};
