import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconCaret } from 'components/Icon/Caret';
import { VerticalContext } from 'lib/ContextTypes';
import ChildrenPropType from 'lib/CustomPropTypes/children';
import {
  ECOMMERCE_DISCLAIMER,
  ECOMMERCE_ABOUT_URL_TODAY,
} from 'lib/commerceUtils';

import styles from './styles.module.scss';


function DisclaimerModal(props) {
  const [open, setOpen] = React.useState(false);
  const vertical = React.useContext(VerticalContext);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const {
    children,
    disclaimerKey: key,
    disclaimerOverride: override,
    showCloseButton,
    small,
    hideLearnMore,
  } = props;

  const getDisclaimerText = () => {
    const hasOverride = key === 'OVERRIDE'
      && typeof override?.disclaimerOverride === 'string'
      && override?.disclaimerOverride.length > 0;
    const disclaimerText = ECOMMERCE_DISCLAIMER[key] || ECOMMERCE_DISCLAIMER.DEFAULT;
    const isNoneDisclaimer = key === 'NONE';

    // If override is provided then use the override text,
    // otherwise use the vertical specific text and
    // if not then use the default text
    let text = hasOverride ? override.disclaimerOverride : disclaimerText;
    const shouldAddLearnMoreLink = vertical === 'today' && !isNoneDisclaimer && !hideLearnMore;
    if (shouldAddLearnMoreLink) {
      text += ` <a data-testid="disclaimer-learn-more" href="${ECOMMERCE_ABOUT_URL_TODAY}">Learn more.</a>`;
    }

    return text;
  };

  return (
    <div
      className={classNames(
        'disclaimer-modal',
        styles.disclaimerModal,
        { [styles.open]: open },
      )}
      data-test="disclaimer"
      data-testid="disclaimer"
    >
      {children ? (
        // Toggle text provided by parent component
        <>
          {children}
          <button
            className={styles.infoBtn}
            data-test="toggle-btn"
            onClick={handleClick}
            type="button"
          >
            <span className="icon icon-info" />
          </button>
        </>
      ) : (
        // Default toggle option
        <>
          <button
            className={styles.toggleBtn}
            data-test="toggle-btn"
            onClick={handleClick}
            type="button"
          >
            {open ? 'Hide Disclaimer' : 'Show Disclaimer'}
            <IconCaret />
          </button>
          <div className={styles.line} />
        </>
      )}

      {/* Card containing disclaimer text */}
      {open && (
        <div
          className={classNames(
            styles.card,
            { [styles.small]: small },
          )}
          data-test="disclaimer-box"
          data-testid="disclaimer-box"
        >
          {showCloseButton && (
            <button
              className="css-reset-btn absolute"
              onClick={closeModal}
              type="button"
            >
              <span className="icon icon-close f2" />
            </button>
          )}
          <p className={styles.text} data-testid="disclaimer-text">
            <div dangerouslySetInnerHTML={{ __html: getDisclaimerText() }} />
          </p>
        </div>
      )}
    </div>
  );
}

DisclaimerModal.propTypes = {
  children: ChildrenPropType,
  disclaimerKey: PropTypes.string,
  disclaimerOverride: PropTypes.string,
  showCloseButton: PropTypes.bool,
  hideLearnMore: PropTypes.bool,
  small: PropTypes.bool,
};

DisclaimerModal.defaultProps = {
  children: '',
  disclaimerKey: 'DEFAULT',
  disclaimerOverride: null,
  showCloseButton: false,
  hideLearnMore: false,
  small: false,
};

export { DisclaimerModal };
