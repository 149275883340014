import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconCaret } from 'components/Icon/Caret';
import { FeatureFlagContext } from 'lib/ContextTypes';

import { NewsNowSVG } from 'components/Icon/NewsNow';
import { NewsNowUpdatedSVG } from 'components/Icon/NewsNowUpdated';
import { NewsNowWhiteSVG } from 'components/Icon/NewsNowWhite';
import styles from './styles.module.scss';


const liveSVG = (
  <svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Live</title>
    <rect width="39" height="22" rx="3" fill="#CB1111" />
    <path d="M5.756 5.66H8.186V15.002H12.506V17H5.756V5.66ZM13.5783 5.66H16.0083V17H13.5783V5.66ZM22.5198 17H20.0718L16.7958 5.66H19.2798L21.3498 13.31H21.4218L23.5098 5.66H25.7958L22.5198 17ZM33.3901 15.002V17H26.5861V5.66H33.3541V7.658H28.9621V10.25H32.3101V12.212H28.9621V15.002H33.3901Z" fill="white" />
  </svg>
);

export const NewsNow = ({ theme }) => {
  const { 'feature-flag-logo-update': isNewsNowLogoUpdateEnabled } = useContext(FeatureFlagContext);

  return (
    <div className={styles.newsNow} data-testid="news-now">
      Get more news
      <span className={classNames(styles.img, styles['live-img'])}>{liveSVG}</span>
      on
      {isNewsNowLogoUpdateEnabled ? (
        <span className={classNames(styles.img, styles['news-now-img-updated'])}>
          { theme === 'light' ? <NewsNowUpdatedSVG /> : <NewsNowWhiteSVG /> }
        </span>
      ) : (
        <span className={classNames(styles.img, styles['news-now-img'])}>
          <NewsNowSVG />
        </span>
      )}
      <span className={classNames(styles.img, styles['chevron-img'])}>
        <IconCaret />
      </span>
    </div>
  );
};

NewsNow.propTypes = {
  theme: PropTypes.string,
};

NewsNow.defaultProps = {
  theme: 'light',
};
