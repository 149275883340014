import React, {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

const IsFullscreen = createContext();
IsFullscreen.displayName = 'IsFullscreen';

export function useIsFullscreen() {
  const isFullscreen = useContext(IsFullscreen);

  // double equal to check for undefined|null
  if (isFullscreen == null) {
    throw new Error('useIsFullscreen called without a IsFullscreen.Provider');
  }

  return isFullscreen;
}

export function IsFullscreenProvider({ children, value }) {
  return (
    <IsFullscreen.Provider value={value}>
      {children}
    </IsFullscreen.Provider>
  );
}

IsFullscreenProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.bool,
};

IsFullscreenProvider.defaultProps = {
  children: undefined,
  value: false,
};
