import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Placeholder } from 'components/Picture/Placeholder';

const NativeLazyLoad = ({
  children,
  placeholder,
  placeholderColor,
  lazyLoad,
}) => {
  // Do not lazyload if disabled via query param
  if (!lazyLoad) {
    return children;
  }

  const { props: childProps = {} } = children;

  // Extract className from child element to add to lazyload wrapper
  let className = '';
  const component = (
    React.Children.map(children, (child) => {
      const additionalChildProps = {};
      // Add lazyloaded attribute
      if (child.props && typeof child.props.lazyloaded === 'boolean') {
        additionalChildProps.lazyloaded = true;
      }
      // Placeholder requested
      if (placeholder) {
        // Copy className to wrapper element
        ({ className } = child.props);
        additionalChildProps.className = null;
      }
      return React.cloneElement(child, additionalChildProps);
    })
  );

  if (placeholder && childProps.responsiveFlavors) {
    return (
      <div className={`relative ${className}`} data-testid="native-lazy-load">
        <div className="absolute absolute-fill z-2 overflow-hidden">
          {component}
        </div>
        <div className="relative z-1">
          <Placeholder
            color={placeholderColor}
            responsiveFlavors={children.props.responsiveFlavors}
          />
        </div>
      </div>
    );
  }

  return component;
};

NativeLazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.bool,
  placeholderColor: PropTypes.string,
  lazyLoad: PropTypes.bool,
};

NativeLazyLoad.defaultProps = {
  placeholder: true,
  placeholderColor: 'knockout-secondary',
  lazyLoad: true,
};

const mapStateToProps = ({
  shared: { disableLazyLoad },
}) => ({
  lazyLoad: !disableLazyLoad,
});

export default connect(mapStateToProps)(NativeLazyLoad);
