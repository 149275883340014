import { useInactivityMonitorUpdate } from 'lib/Hooks/useInactivityMonitorUpdate';
import { useIsVideoPaused } from './context/state/isPaused';
import { useIsUIVisible } from './context/state/isUIVisible';

/**
 * @param {object} props
 * @param {boolean} props.isStickyEnabled
 */
export function InactivityMonitorUpdate({ isStickyEnabled }) {
  const isPaused = useIsVideoPaused();
  const isVisible = useIsUIVisible();

  useInactivityMonitorUpdate(!isPaused && (isVisible || isStickyEnabled));

  return null;
}
