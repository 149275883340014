const trim = (text, cutoff) => {
  if (!text) {
    return text;
  }
  const firstHundredCharacters = text.substring(0, cutoff);
  let lastChar = firstHundredCharacters.charAt(firstHundredCharacters.length - 1);
  let lastCharOffset = 0;
  while (lastChar !== ' ' && lastCharOffset < cutoff) {
    lastCharOffset += 1;
    lastChar = firstHundredCharacters.charAt((firstHundredCharacters.length - 1) - lastCharOffset);
  }
  return `${text.substring(0, (firstHundredCharacters.length - 1) - lastCharOffset)}...`;
};

export default function trimText(text, cutoff) {
  if (!text.length || text.length <= cutoff) {
    return text;
  }
  return trim(text, cutoff);
}
