import React from 'react';
import PropTypes from 'prop-types';

export function ClosedCaption({ className }) {
  return (
    <svg
      width="29"
      height="16"
      viewBox="0 0 29 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1"
        y="1"
        width="27"
        height="14"
        rx="1"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.9795 5.25977C11.3089 5.25977 10.7816 5.4974 10.3975 5.97266C10.0133 6.44792 9.82129 7.10384 9.82129 7.94043C9.82129 8.81608 10.0052 9.47852 10.373 9.92773C10.7441 10.377 11.2796 10.6016 11.9795 10.6016C12.2822 10.6016 12.5752 10.5723 12.8584 10.5137C13.1416 10.4518 13.4362 10.3737 13.7422 10.2793V11.2803C13.1823 11.4919 12.5475 11.5977 11.8379 11.5977C10.793 11.5977 9.99056 11.2819 9.43066 10.6504C8.87077 10.0156 8.59082 9.10905 8.59082 7.93066C8.59082 7.18848 8.72591 6.53906 8.99609 5.98242C9.26953 5.42578 9.66341 4.99935 10.1777 4.70312C10.6921 4.4069 11.2959 4.25879 11.9893 4.25879C12.7184 4.25879 13.3923 4.41178 14.0107 4.71777L13.5908 5.68945C13.3499 5.57552 13.0944 5.47624 12.8242 5.3916C12.5573 5.30371 12.2757 5.25977 11.9795 5.25977ZM17.9174 5.25977C17.2468 5.25977 16.7195 5.4974 16.3354 5.97266C15.9512 6.44792 15.7592 7.10384 15.7592 7.94043C15.7592 8.81608 15.9431 9.47852 16.3109 9.92773C16.682 10.377 17.2175 10.6016 17.9174 10.6016C18.2201 10.6016 18.5131 10.5723 18.7963 10.5137C19.0795 10.4518 19.3741 10.3737 19.6801 10.2793V11.2803C19.1202 11.4919 18.4854 11.5977 17.7758 11.5977C16.7309 11.5977 15.9285 11.2819 15.3686 10.6504C14.8087 10.0156 14.5287 9.10905 14.5287 7.93066C14.5287 7.18848 14.6638 6.53906 14.934 5.98242C15.2074 5.42578 15.6013 4.99935 16.1156 4.70312C16.6299 4.4069 17.2338 4.25879 17.9271 4.25879C18.6563 4.25879 19.3301 4.41178 19.9486 4.71777L19.5287 5.68945C19.2878 5.57552 19.0323 5.47624 18.7621 5.3916C18.4952 5.30371 18.2136 5.25977 17.9174 5.25977Z"
      />
    </svg>
  );
}

ClosedCaption.propTypes = {
  className: PropTypes.string,
};

ClosedCaption.defaultProps = {
  className: undefined,
};
