import classNames from 'classnames';
import React from 'react';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { getPIDforPlayerKey, STREAM_KEYS } from 'lib/playmaker';
import { imageAltForImage } from 'lib/imageAlt';
import { getMainArtAutoPlayValues } from 'lib/getMainArtAutoPlayValues';

import { ArticleDek } from 'components/Article/ArticleDek';
import Caption from 'components/Caption';
import InlineVideo from 'components/InlineVideo';
import { Picture } from 'components/Picture';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { PrimaryMediaBanner } from 'components/PrimaryMediaBanner';

export const getMediaPresentationStyle = (context) => {
  const {
    isRecipe,
  } = context;
  const style = context?.content?.primaryMedia?.presentation?.style;
  const type = context?.content?.primaryMedia?.type;

  if ((type && ['embeddedPlaymakerLiveVideo', 'embeddedVideo'].includes(type)) || isRecipe) {
    return 'default';
  }
  if (style && ['default', 'spread'].includes(style)) {
    return style;
  }
  return 'none';
};

export const renderDek = (context, isLiveBreakingNews) => {
  const {
    content: {
      dek,
    },
    isRecipe,
    isShoppingSection,
  } = context;

  if (!dek || isRecipe) {
    return null;
  }

  const mediaPresentationStyle = getMediaPresentationStyle(context);

  return (
    <ArticleDek
      dek={dek}
      isShoppingSection={isShoppingSection}
      isLiveBreakingNews={isLiveBreakingNews}
      hasNoImage={mediaPresentationStyle === 'none'}
      data-test="article-dek"
    />
  );
};

export const renderCaption = (context) => {
  const {
    block,
    content: {
      primaryImage,
      primaryMedia,
    } = {},
    isRecipe,
    isLiveBlog,
    isShowBlog,
    heroLayoutType,
  } = context;
  const {
    image,
    type,
  } = primaryMedia || {};


  if (!type && !isRecipe) {
    return null;
  }
  const isPlaymaker = type === 'embeddedPlaymakerLiveVideo';

  const captionImage = (isPlaymaker || isRecipe)
    ? primaryImage
    : image;
  return (
    <Caption
      image={captionImage}
      className={classNames(
        `${block}__caption layout-grid-item`,
        {
          [`${block}__caption--playmaker`]: isPlaymaker,
          'grid-col-10-xl grid-col-push-2-xl': !isLiveBlog && !isShowBlog && !heroLayoutType?.wide && !isRecipe,
          'grid-col-7_5-l grid-col-6-xl grid-col-push-2-xl': isLiveBlog && !heroLayoutType?.wide,
          [`${block}__caption--recipe dn-print`]: isRecipe,
          'grid-col-11-m grid-col-10-xl grid-col-push-1-xl': heroLayoutType?.wide,
          'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe,
        },
      )}
    />
  );
};

export const renderImage = (context) => {
  const {
    block,
    isRecipe,
    isShowBlog,
    isPresentationStyleWide,
    content: {
      primaryMedia,
      primaryImage,
    },
  } = context;

  let flavors;
  if (isShowBlog) {
    flavors = {
      s: AIMS_FLAVORS.FIT_760w,
      l: AIMS_FLAVORS.FIT_560w,
    };
  } else if (isPresentationStyleWide) {
    flavors = {
      s: AIMS_FLAVORS.FIT_760w,
      m: AIMS_FLAVORS.FIT_1000w,
      l: AIMS_FLAVORS.FIT_1240w,
      xl: AIMS_FLAVORS.FIT_1520w,
    };
  } else {
    flavors = {
      s: AIMS_FLAVORS.FIT_560w,
      m: AIMS_FLAVORS.FIT_760w,
      l: AIMS_FLAVORS.FIT_1000w,
    };
  }

  const mediaToUse = (isRecipe ? primaryImage : primaryMedia.image) || {};
  const { width, height } = mediaToUse;
  const url = mediaToUse.url.primary;

  return (
    <figure
      className={classNames(
        `${block}__main`,
        {
          mb4: isShowBlog,
        },
      )}
    >
      <Picture
        preload
        loadingLazy={false}
        url={url}
        className={`${block}__main-image`}
        alt={imageAltForImage(mediaToUse)}
        flavor="fit"
        responsiveFlavors={flavors}
        sizes={flavors}
        originalHeight={height}
        originalWidth={width}
      />
    </figure>
  );
};

const renderVideo = (context) => {
  const {
    block,
    content: {
      primaryMedia,
      subType,
    },
    isChromeless,
    socialMediaProfiles,
    vertical,
    primaryMediaBannerEnabled,
    pipAlignDesktop,
    pipAlignMobile,
  } = context;

  const {
    autoPlay,
    onlyAutoplayMuted,
  } = getMainArtAutoPlayValues(primaryMedia, isChromeless, vertical);

  const mainArtAutoPlayCapabilities = {
    canAutoplayMuted: true,
    canAutoplayUnmuted: false,
    isTestingAutoplayCapabilities: false,
  };

  return (
    <div className={`${block}__video`}>
      <InlineVideo
        loadingLazy={false}
        autoPlay={autoPlay}
        disablePlaylistAutoplay={primaryMedia.disablePlaylistAutoplay}
        autoplayCapabilities={mainArtAutoPlayCapabilities}
        isHero
        onlyAutoplayMuted={onlyAutoplayMuted}
        social={socialMediaProfiles}
        video={primaryMedia.video}
        disableSticky={false}
        pipAlignDesktop={pipAlignDesktop}
        pipAlignMobile={pipAlignMobile}
      />
      {primaryMediaBannerEnabled
      && vertical === 'news'
      && subType !== 'FEATURED'
      && (
        <PrimaryMediaBanner
          type="newsNow"
          dataIcid="article_primary-media-banner-cta"
        />
      )}
    </div>
  );
};

const renderLiveVideo = (context) => {
  const {
    block,
    vertical,
    authenticated,
    provider,
    hasTempPass,
    videoError,
    primaryMediaBannerEnabled,
  } = context;

  const subType = context?.content?.subType;
  let playmakerKey = context?.content?.primaryMedia?.playmakerLiveVideo?.playmakerId;
  const pid = getPIDforPlayerKey(playmakerKey);

  // Temporary fix for MSNBC having the wrong stream key
  if (playmakerKey === STREAM_KEYS.MSNBC) {
    playmakerKey = STREAM_KEYS.MSNBC_TVE;
  }

  if (pid) {
    const videoContent = (
      <OmegaFeatureFlagSwitch
        ifOmega={(
          <OmegaVideoPlayer
            autoplay
            mutedAutoplay
            stickyEnabled
            stream={playmakerKey}
          />
        )}
      >
        <VideoContainer stickyEnabled>
          <TapToUnmuteButton />
          <CoreVideoPlayer stream={playmakerKey} autoplay mutedAutoplay />
          <VideoControlRack />
        </VideoContainer>
      </OmegaFeatureFlagSwitch>
    );

    return (
      <div
        className={
          classNames(`${block}__video`,
            {
              'article-hero__video__subheader-large': ((authenticated && provider && !hasTempPass) || videoError),
              'article-hero__video__subheader-small': (!provider || hasTempPass),
            })

        }
      >
        <div
          className={
            classNames(`${block}__video-outer`)
          }

        >
          <div className={`${block}__video-inner`}>
            {videoContent}
          </div>
        </div>
        {primaryMediaBannerEnabled
          && vertical === 'news'
          && subType !== 'FEATURED'
          && <PrimaryMediaBanner type="newsNow" />}
      </div>
    );
  }
  return null;
};

export const getMedia = (context) => {
  const type = context?.content?.primaryMedia?.type;
  switch (type) {
    case 'embeddedVideo':
      return renderVideo(context);
    case 'embeddedPlaymakerLiveVideo':
      return renderLiveVideo(context);
    case 'embeddedImage':
      return renderImage(context);
    default:
      return null;
  }
};

export const getDataAuthors = (context) => { // only used for recipe print
  const {
    content,
    isRecipe,
  } = context;

  if (!isRecipe) {
    return null;
  }
  const { authors } = content || {};
  return authors.map((auth) => auth.name).join(', ');
};

export const getPrimaryMediaType = (context) => {
  const mediaType = context?.content?.primaryMedia?.type ?? null;

  switch (mediaType) {
    case 'embeddedVideo':
    case 'embeddedPlaymakerLiveVideo':
      return 'video';
    case 'embeddedImage':
      return 'image';
    default:
      return null;
  }
};

export const getDataActivityMapID = ({ pageRegion = 'not-set', componentName, componentTitle }) => {
  const sanitizedComponentTitle = componentTitle
    ? componentTitle.toLowerCase().replace(/ /g, '-')
    : '';
  return sanitizedComponentTitle
    ? `${componentName}-${pageRegion}-${sanitizedComponentTitle}`
    : `${componentName}-${pageRegion}`;
};
